import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store/rootStore';
import { formatPhone } from '../../Base/Phone/Formatter';
import HighlightedText from '../../Base/Utils/HighlightedText';
const { Text } = Typography;
function ChatTitle({ chat, highlighted, maxLength = 40, showId = 'all', className, }) {
    const { channels, phoneFormat, defaultCountry } = useStore();
    const [title, setTitle] = useState(chat.title || '---');
    useEffect(() => {
        if (chat.title) {
            setTitle(chat.title);
        }
        else if (showId !== 'none') {
            const adapter = channels.adaptersByAccountId[`${chat.channelInfo.name}${chat.channelInfo.accountId}`];
            if (channels.hasFeatures([MessagingChannelFeature.contactPhoneNumberId], adapter)) {
                setTitle(formatPhone(chat.channelInfo.id, phoneFormat, defaultCountry));
            }
            else if (showId === 'all' || channels.hasFeatures([MessagingChannelFeature.contactGlobalId], adapter)) {
                setTitle(chat.channelInfo.id);
            }
        }
        else {
            setTitle('---');
        }
    }, [chat._id, channels.adaptersByAccountId, phoneFormat, defaultCountry, showId, chat.title]);
    let cropped = title;
    if (cropped.length > maxLength) {
        cropped = cropped.substring(0, maxLength) + '...';
    }
    return (React.createElement(Text, { className: className }, highlighted ? (React.createElement(HighlightedText, { text: cropped, highlighted: highlighted })) : (cropped)));
}
/**
 * Show chat title, optinally with fallback to formatted ID and search highlight
 */
export default observer(ChatTitle);
