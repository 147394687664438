var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { API_PATH } from '../../constants';
import { action, observable, runInAction, computed, makeObservable, when, } from 'mobx';
import { getAxios } from '../../backend';
import { MessagingChannel, MessagingChannelFeature, } from '@whatsper/texterchat-common';
import { omit, pick } from 'lodash';
import channelThemePresetsJSON from '../../assets/themePresetsChannel/presets.json';
import { importAllWebpack } from '../../helpers';
const images = importAllWebpack(require.context('../../assets/themePresetsChannel/icons/', false, /\.(svg)$/));
export const channelThemePresets = Object.entries(channelThemePresetsJSON).reduce((acc, [key, value], i) => {
    Object.entries(value).map(([k, v]) => {
        acc[key] = Object.assign(Object.assign({}, acc[key]), { [k]: Object.assign(Object.assign({}, v), { icon: images.find((image) => image.startsWith(`/static/media/${v.icon}`)) }) });
    });
    return acc;
}, {});
class ChannelsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "adapters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        Object.defineProperty(this, "accounts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        Object.defineProperty(this, "currentAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "themes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "partnerTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "webhookLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "webhookMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "webhookError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "setInitialWAAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                /* set init whatsapp account for template store */
                const defaultTemplateAccount = this.accounts.find((acc) => acc.channel === MessagingChannel.whatsapp);
                if (defaultTemplateAccount) {
                    this.rootStore.templateMessagesStore.setSelectedAccount({
                        id: defaultTemplateAccount.accountId,
                        name: defaultTemplateAccount.channel,
                    });
                }
            }
        });
        Object.defineProperty(this, "fetchChannelsAdapters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHANNELS}/adapters`);
                })
                    .then(({ data }) => {
                    runInAction(() => this.adapters.replace(data));
                });
            })
        });
        Object.defineProperty(this, "fetchChannelsAccounts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHANNELS}/accounts`);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.accounts.replace(data);
                        this._setThemes(data);
                    });
                });
            })
        });
        Object.defineProperty(this, "getChannelAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId, channel) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHANNELS}/accounts/${channel}/${accountId}`);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.currentAccount = data;
                    });
                });
            })
        });
        Object.defineProperty(this, "createChannelAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (adapterName, account) => __awaiter(this, void 0, void 0, function* () {
                const { themeId } = account, postBody = __rest(account, ["themeId"]);
                if (themeId) {
                    const theme = this._getThemeById(themeId);
                    postBody.style = { theme: JSON.stringify(theme) };
                }
                yield getAxios().then((axios) => {
                    return axios
                        .post(`${API_PATH.CHANNELS}/accounts/${adapterName}`, postBody)
                        .then(({ data }) => {
                        runInAction(() => {
                            this.currentAccount = data;
                            this.accounts.push(data);
                        });
                    });
                });
            })
        });
        Object.defineProperty(this, "editChannelAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (account, credentials) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                let theme;
                const themeUpdated = account.themeId && ((_a = this.themes[account.accountId]) === null || _a === void 0 ? void 0 : _a.id) !== account.themeId;
                if (themeUpdated) {
                    theme = this._updateTheme(account.accountId, account.channel, account === null || account === void 0 ? void 0 : account.themeId);
                }
                const patchBody = Object.assign(Object.assign(Object.assign({}, pick(account, ['title', 'isDefault', 'bot', 'botDisable'])), (themeUpdated ? { style: { theme: JSON.stringify(theme) } } : {})), (credentials ? { credentials } : {}));
                yield getAxios().then((axios) => {
                    return axios
                        .patch(`${API_PATH.CHANNELS}/accounts/${account.adapterName}/${account.accountId}`, patchBody)
                        .then(({ data }) => {
                        runInAction(() => {
                            this.currentAccount = data;
                        });
                        this.accounts = observable(this.accounts.map((account) => {
                            if (account.accountId === data.accountId)
                                return Object.assign(Object.assign({}, account), data);
                            return account;
                        }));
                    });
                });
            })
        });
        Object.defineProperty(this, "deleteChannelAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (channel) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios().then((axios) => {
                    return axios.delete(`${API_PATH.CHANNELS}/accounts/${channel.channel}/${channel.accountId}`);
                });
            })
        });
        Object.defineProperty(this, "connectOrDisconnectWebook", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (channel, disconnect) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.webhookLoading = true;
                    this.webhookError = false;
                });
                const body = disconnect ? { remove: true } : {};
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.CHANNELS}/accounts/${channel.channel}/${channel.accountId}/set-webhooks`, body);
                })
                    .then(({ status }) => {
                    if (status === 200) {
                        runInAction(() => {
                            if (disconnect) {
                                this.webhookMessage = 'webhookDisconnected';
                            }
                            else {
                                this.webhookMessage = 'webhookConnected';
                            }
                        });
                    }
                })
                    .catch(() => {
                    runInAction(() => {
                        this.webhookError = true;
                    });
                })
                    .finally(() => {
                    runInAction(() => (this.webhookLoading = false));
                });
            })
        });
        Object.defineProperty(this, "resetWebhookState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.webhookLoading = false;
                this.webhookMessage = '';
                this.webhookError = false;
            }
        });
        Object.defineProperty(this, "setLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (loading) => {
                this.loading = loading;
            }
        });
        Object.defineProperty(this, "setPartnerTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.partnerTheme = name;
            }
        });
        Object.defineProperty(this, "getAccountAdapter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => {
                const account = this.getAccount(accountId);
                if (!account)
                    return null;
                const adapter = this.adapters.find((adapter) => adapter.name === account.adapterName);
                return adapter || null;
            }
        });
        Object.defineProperty(this, "getAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                return this.accounts.find(({ accountId, channel }) => accountId === id.id && channel === id.name);
            }
        });
        Object.defineProperty(this, "_getThemeById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (themeId) => {
                const themes = Object.values(channelThemePresets).reduce((acc, channel) => {
                    const result = [...acc, ...Object.values(channel)];
                    return result;
                }, []);
                const theme = themes.find((theme) => theme.id === themeId);
                return theme || null;
            }
        });
        Object.defineProperty(this, "_updateTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId, channel, themeId) => {
                const theme = Object.values(channelThemePresets[channel]).find((theme) => theme.id === themeId);
                this.themes[accountId] = theme;
                return theme;
            }
        });
        Object.defineProperty(this, "_setThemes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accounts) => {
                const themes = accounts.reduce((acc, { accountId, style, channel }) => {
                    try {
                        return Object.assign(Object.assign({}, acc), { [accountId]: style && style.theme ? JSON.parse(style.theme) : this.themes[channel] });
                    }
                    catch (error) {
                        console.error('Error parsing theme value', error);
                        return Object.assign(Object.assign({}, acc), { [accountId]: this.themes[channel] });
                    }
                }, {});
                this.themes = Object.assign(Object.assign({}, this.themes), themes);
            }
        });
        /**
         * hasFeatures
         *
         * @param features Array of requested features
         * @param customAdapter A custom adapter to verify features against (default is activeChat adapter)
         * @param forceCustomAdapter Force the use of custom adapter (ignore default)
         * @returns {boolean} The adapter has all the required features
         */
        Object.defineProperty(this, "hasFeatures", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (features, customAdapter, forceCustomAdapter) => {
                const { chat } = this.rootStore.activeChatStore;
                let adapter = null;
                if (customAdapter) {
                    adapter = customAdapter;
                }
                else if (forceCustomAdapter) {
                    return false;
                }
                else {
                    adapter = chat ? this.getAccountAdapter({
                        id: chat === null || chat === void 0 ? void 0 : chat.channelInfo.accountId,
                        name: chat === null || chat === void 0 ? void 0 : chat.channelInfo.name,
                    }) : null;
                }
                if (!adapter)
                    return false;
                return features.every((feature) => adapter === null || adapter === void 0 ? void 0 : adapter.features.includes(feature));
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.themes = {};
        /* default channel themes */
        for (const channel in MessagingChannel) {
            this.themes[channel] = channelThemePresets[channel].A;
        }
        if (this.accounts.length) {
            this.setInitialWAAccount();
        }
        else {
            // Wait till loaded
            const disposeReaction = when(() => !!this.accounts.length, () => {
                this.setInitialWAAccount();
                disposeReaction();
            });
        }
    }
    /**
     * Channels accounts mapped by channel name + account ID concatenated string
     */
    get accountsById() {
        const accounts = {};
        for (const account of this.accounts) {
            accounts[`${account.channel}${account.accountId}`] = account;
        }
        return accounts;
    }
    /**
     * Channels adapters mapped by name
     */
    get adaptersByName() {
        const adapters = {};
        for (const adapter of this.adapters) {
            adapters[adapter.name] = adapter;
        }
        return adapters;
    }
    /**
     * Channels adapters mapped by channel name + account ID concatenated string
     */
    get adaptersByAccountId() {
        const adapters = {};
        for (const { channel, accountId, adapterName } of this.accounts) {
            if (this.adaptersByName[adapterName]) {
                adapters[`${channel}${accountId}`] = this.adaptersByName[adapterName];
            }
        }
        return adapters;
    }
    get hasAccounts() {
        return this.accounts.length > 1;
    }
    get templateAccounts() {
        return this.accounts.filter((acc) => {
            const adapter = this.adapters.find((adapter) => adapter.channel === acc.channel);
            return this.hasFeatures([MessagingChannelFeature.templates], adapter, true);
        });
    }
    get defaultAccount() {
        return this.accounts.find((account) => account.isDefault) || null;
    }
    get themesIdsInUse() {
        const themesInUse = omit(this.themes, Object.values(MessagingChannel));
        return Object.values(themesInUse)
            .filter(Boolean)
            .map((theme) => theme.id);
    }
}
__decorate([
    observable
], ChannelsStore.prototype, "currentAccount", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "themes", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "partnerTheme", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "loading", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "webhookLoading", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "webhookMessage", void 0);
__decorate([
    observable
], ChannelsStore.prototype, "webhookError", void 0);
__decorate([
    computed
], ChannelsStore.prototype, "accountsById", null);
__decorate([
    computed
], ChannelsStore.prototype, "adaptersByName", null);
__decorate([
    computed
], ChannelsStore.prototype, "adaptersByAccountId", null);
__decorate([
    computed
], ChannelsStore.prototype, "hasAccounts", null);
__decorate([
    computed
], ChannelsStore.prototype, "templateAccounts", null);
__decorate([
    computed
], ChannelsStore.prototype, "defaultAccount", null);
__decorate([
    computed
], ChannelsStore.prototype, "themesIdsInUse", null);
__decorate([
    action
], ChannelsStore.prototype, "fetchChannelsAdapters", void 0);
__decorate([
    action
], ChannelsStore.prototype, "fetchChannelsAccounts", void 0);
__decorate([
    action
], ChannelsStore.prototype, "getChannelAccount", void 0);
__decorate([
    action
], ChannelsStore.prototype, "createChannelAccount", void 0);
__decorate([
    action
], ChannelsStore.prototype, "editChannelAccount", void 0);
__decorate([
    action
], ChannelsStore.prototype, "deleteChannelAccount", void 0);
__decorate([
    action
], ChannelsStore.prototype, "connectOrDisconnectWebook", void 0);
__decorate([
    action
], ChannelsStore.prototype, "resetWebhookState", void 0);
__decorate([
    action
], ChannelsStore.prototype, "setLoading", void 0);
__decorate([
    action
], ChannelsStore.prototype, "setPartnerTheme", void 0);
export default ChannelsStore;
