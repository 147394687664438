import { MESSAGE_DIRECTION, MessageContentsType, } from '@whatsper/texterchat-common';
import React from 'react';
import classNames from 'classnames';
import InstagramMessage from './messages/InstagramMessage';
import WhatsAppMessage from './messages/WhatsAppMessage';
import MessengerMessage from './messages/MessengerMessage';
import UnsupportedMessage from './messages/UnsupportedMessage';
import EmailMessage from './messages/EmailMessage';
import s from './SpecialMessage.module.scss';
/**
 * SpecialMessage component is a wrapper for all special message types,
 * or other type messages with {message.special} property. It takes whole Message component as children to be rendered, and
 * it renders special message parts if they are present. For special type message, children are not rendered.
 *
 * @param type - Message type
 * @param special - Special message object
 * @param afterFooterRef - Ref for after footer element
 * @param direction - Message direction
 * @param channel - Messaging channel (WhatsApp, Instagram, Messenger)
 * @param text - A regular message text
 * @param metadata - A regular message metadata
 * @param children - A full message component
 * @returns - A full message of type "special" or parts from special param if present
 *
 */
const SpecialMessage = ({ type, special, afterFooterRef, metadata, direction, channel, text, children, }) => {
    if (!special) {
        return React.createElement(React.Fragment, null, children);
    }
    /* If message is legacy template, don't render whatsapp, nor children */
    const isLegacyTemplateDataMessage = !!(metadata === null || metadata === void 0 ? void 0 : metadata.templateMessageData);
    return (React.createElement("div", { dir: "auto", className: classNames(s.specialContainer, {
            [s.incoming]: direction === MESSAGE_DIRECTION.IN,
        }) },
        special.unsupportedByProvider && React.createElement(UnsupportedMessage, { channel: channel }),
        special.whatsapp && !isLegacyTemplateDataMessage && (React.createElement(WhatsAppMessage, { message: special.whatsapp, text: text, afterFooterRef: afterFooterRef })),
        special.instagram && React.createElement(InstagramMessage, { message: special.instagram, direction: direction }),
        special.messenger && React.createElement(MessengerMessage, { message: special.messenger }),
        special.email && React.createElement(EmailMessage, { message: special.email }),
        (type !== MessageContentsType.special || isLegacyTemplateDataMessage) && React.createElement(React.Fragment, null, children)));
};
export default SpecialMessage;
