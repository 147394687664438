import { MessagingChannel } from '@whatsper/texterchat-common';
import React from 'react';
import { Trans } from 'react-i18next';
import styles from '../SpecialMessage.module.scss';
const UnsupportedMessage = ({ channel }) => {
    const renderChannelSpecificMessage = () => {
        switch (channel) {
            case MessagingChannel.instagram:
                return React.createElement(Trans, { i18nKey: "messageUnavailableInstagram" });
            default:
                return null;
        }
    };
    return (React.createElement("div", { className: styles.unsupportedMessageContainer },
        React.createElement(Trans, { i18nKey: "messageUnavailable" }),
        React.createElement("div", { className: styles.unsupportedMessageType }, renderChannelSpecificMessage())));
};
export default UnsupportedMessage;
