import React, { Component } from "react";
import { observer } from "mobx-react";
import { Select, Typography } from "antd";
import { Trans, withTranslation } from "react-i18next";
import classnames from "classnames";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";
import GeneratorLabel from "../../GeneratorLabel";
import { RICH_TEMPLATE_BUTTON_TYPE, CALL_TO_ACTION_TYPES, } from "../../../../../../constants";
import { withStore } from "../../../../../../store/rootStore";
import { ReactComponent as GreenPlus } from "../../../../../../assets/icons/plus/greenPlus.svg";
import { ReactComponent as Plus } from "../../../../../../assets/icons/plus/quickRepliesPlus.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/icons/deleteIcon/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/pencil/edit.svg";
import ActiveIcon from "../../../../ActiveIcon";
import MessagePreview from "../MessagePreview";
import MessagePreviewModal from "../MessagePreviewModal";
import CommonButton from "../../../../common/CommonButton";
import { MyInput, MySelect } from "../../../../../Base/Form";
import { getDirection } from "../../../../../../helpers";
import { TEMPLATE_HEADER_TYPE, WhatsApp } from "@whatsper/texterchat-common";
import s from "./RichParams.module.scss";
const { Option } = Select;
const { Text } = Typography;
class RichParams extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                callToActionButtonLimit: 2,
                quickReplyButtonLimit: 3,
                sampleDataModalIsOpen: false,
                fillButton: false,
            }
        });
        Object.defineProperty(this, "actionButtonsValidation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (button) => {
                var _a;
                if (button.type === CALL_TO_ACTION_TYPES.URL && button.url.length > 0 && !button.url.includes("https://")) {
                    return (React.createElement(Text, { className: s.buttonsError },
                        React.createElement(Trans, { i18nKey: "urlMustInclude" })));
                }
                else if (button.type === CALL_TO_ACTION_TYPES.PHONE_NUMBER &&
                    button.phone_number &&
                    button.phone_number.length > 0 &&
                    !button.phone_number.startsWith("+")) {
                    return (React.createElement(Text, { className: s.buttonsError },
                        React.createElement(Trans, { i18nKey: "phoneMustStartWith" })));
                }
                else if (((_a = button.text) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                    return (React.createElement(Text, { className: s.buttonsError },
                        React.createElement(Trans, { i18nKey: "textFieldIsRequired" })));
                }
                return null;
            }
        });
        Object.defineProperty(this, "blockField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { currentTemplate } = this.props.store.templateMessagesStore;
                return !!(currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.status);
            }
        });
        Object.defineProperty(this, "blockMessagePreviewField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.templateIsApproved())
                    return false;
                return this.blockField();
            }
        });
        Object.defineProperty(this, "templateIsApproved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { currentTemplate } = this.props.store.templateMessagesStore;
                return (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.status) === WhatsApp.TemplateLocalizationStatus.APPROVED;
            }
        });
        Object.defineProperty(this, "switchOpenSampleDataModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { setTempExampleParam, bodyExampleParams } = this.props.store.templateMessagesStore;
                const { sampleDataModalIsOpen } = this.state;
                if (!sampleDataModalIsOpen) {
                    setTempExampleParam(bodyExampleParams);
                }
                else {
                    setTempExampleParam(null);
                }
                this.setState({ sampleDataModalIsOpen: !sampleDataModalIsOpen });
            }
        });
    }
    render() {
        const { t } = this.props;
        const { richTemplateHeaderType, richTemplateHeader, richTemplateHeaderUrl, templateMessage, richTemplateFooter, richTemplateButtonType, callToActionButtons, quickReplyButtons, handleSetHeader, handleSetFooter, handleRichTemplateHeaderType, handleSetButtonType, handleAddButton, handleRemoveButton, handleButtonTextChange, hasAllSampleParamsAdded, hasAtLeastOneSampleParamAdded, hasSampleParamsSetup, } = this.props.store.templateMessagesStore;
        const { callToActionButtonLimit, quickReplyButtonLimit, sampleDataModalIsOpen, fillButton } = this.state;
        const hasFile = richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType);
        return (React.createElement("div", { className: s.richBlock },
            React.createElement("div", { className: s.inputsBlock },
                React.createElement("div", { className: s.templateAddHeader },
                    React.createElement(GeneratorLabel, { textKey: "header" }),
                    React.createElement(MySelect, { disabled: this.blockField(), defaultValue: null, onChange: (value) => {
                            handleSetHeader('');
                            handleRichTemplateHeaderType(value);
                        }, value: richTemplateHeaderType !== null && richTemplateHeaderType !== void 0 ? richTemplateHeaderType : '', size: "large", options: [
                            {
                                label: React.createElement(Trans, { i18nKey: "none" }),
                                value: ''
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "text" }),
                                value: TEMPLATE_HEADER_TYPE.TEXT,
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "image" }),
                                value: TEMPLATE_HEADER_TYPE.IMAGE,
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "video" }),
                                value: TEMPLATE_HEADER_TYPE.VIDEO,
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "file" }),
                                value: TEMPLATE_HEADER_TYPE.DOCUMENT,
                            }
                        ] }),
                    richTemplateHeaderType === TEMPLATE_HEADER_TYPE.TEXT && (React.createElement(MyInput, { value: richTemplateHeader, onChangeHandler: handleSetHeader, placeholder: t("headerText"), disabled: this.blockField(), size: "large" }))),
                React.createElement("div", { className: s.templateAddFooter },
                    React.createElement(GeneratorLabel, { textKey: "footer" }),
                    React.createElement(MyInput, { value: richTemplateFooter || '', onChangeHandler: (value) => {
                            if (value.length <= 60) {
                                handleSetFooter(value);
                            }
                        }, placeholder: t("footerText"), size: "large", disabled: this.blockField() })),
                React.createElement("div", { className: s.templateAddButtons },
                    React.createElement(GeneratorLabel, { textKey: "buttons" }),
                    React.createElement(MySelect, { disabled: this.blockField(), defaultValue: null, onChange: handleSetButtonType, value: richTemplateButtonType !== null && richTemplateButtonType !== void 0 ? richTemplateButtonType : '', size: "large", options: [
                            {
                                label: React.createElement(Trans, { i18nKey: "none" }),
                                value: '',
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "quickReply" }),
                                value: RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY,
                            },
                            {
                                label: React.createElement(Trans, { i18nKey: "callToAction" }),
                                value: RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION,
                            }
                        ] })),
                richTemplateButtonType === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY && (React.createElement("div", null, quickReplyButtons === null || quickReplyButtons === void 0 ? void 0 :
                    quickReplyButtons.map((button, i) => (React.createElement("div", { key: i, className: classnames(s.templateAddButtonBlock, {
                            [s.inputWithDeleteBlock]: i > 0,
                            [s.inputDisabled]: this.blockField(),
                        }) },
                        React.createElement(MyInput, { value: button.text, onChangeHandler: (value) => handleButtonTextChange(value, i, RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY, "text"), maxLength: 20, placeholder: t("text"), size: "large", disabled: this.blockField(), className: classnames({ [s.inputWithDelete]: i > 0 }) }),
                        i > 0 && (React.createElement(ActiveIcon, { component: DeleteIcon, action: () => handleRemoveButton(RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY, i), disabled: this.blockField(), additionalStyles: s.deleteIcon, alert: true }))))),
                    quickReplyButtons && quickReplyButtons.length < quickReplyButtonLimit && (React.createElement(CommonButton, { disabled: this.blockField(), action: () => handleAddButton(RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY), additionalStyles: s.addButton },
                        React.createElement(Icon, { component: GreenPlus }))))),
                richTemplateButtonType === RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION && (React.createElement("div", null, callToActionButtons === null || callToActionButtons === void 0 ? void 0 :
                    callToActionButtons.map((button, i) => (React.createElement("div", { key: i, className: s.actionButtonBlock },
                        this.actionButtonsValidation(button),
                        React.createElement("div", { className: s.templateAddButtonBlock },
                            React.createElement("div", { className: classnames(s.textInputBlock, { [s.inputWithDeleteBlock]: i > 0 }) },
                                React.createElement(MyInput, { value: button.text, onChangeHandler: (value) => {
                                        handleButtonTextChange(value, i, RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION, "text");
                                    }, placeholder: t("text"), className: classnames({ [s.inputWithDelete]: i > 0 }), disabled: this.blockField(), maxLength: 20, size: "large" }),
                                i > 0 && (React.createElement(ActiveIcon, { component: DeleteIcon, action: () => {
                                        if (!this.blockField()) {
                                            handleRemoveButton(RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION, i);
                                        }
                                    }, additionalStyles: s.deleteIcon, alert: true }))),
                            React.createElement("div", { className: s.buttonDataBlock },
                                React.createElement(MySelect, { disabled: this.blockField(), defaultValue: CALL_TO_ACTION_TYPES.URL, className: s.shortSelect, onChange: (value) => handleButtonTextChange(value, i, RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION, "type"), value: button.type, size: "large" },
                                    React.createElement(Option, { value: CALL_TO_ACTION_TYPES.URL, disabled: callToActionButtons.length > 1 &&
                                            (callToActionButtons === null || callToActionButtons === void 0 ? void 0 : callToActionButtons.find((button, j) => j !== i).type) === CALL_TO_ACTION_TYPES.URL, className: classnames(s.option, {
                                            [s.rtl]: getDirection() === "rtl",
                                        }) },
                                        React.createElement(Text, { className: s.selectText },
                                            React.createElement(Trans, { i18nKey: "url" }))),
                                    React.createElement(Option, { value: CALL_TO_ACTION_TYPES.PHONE_NUMBER, disabled: callToActionButtons.length > 1 &&
                                            callToActionButtons.find((button, j) => j !== i).type === CALL_TO_ACTION_TYPES.PHONE_NUMBER, className: classnames(s.option, {
                                            [s.rtl]: getDirection() === "rtl",
                                        }) },
                                        React.createElement(Text, { className: s.selectText },
                                            React.createElement(Trans, { i18nKey: "mobile" })))),
                                React.createElement(MyInput, { value: button.type === CALL_TO_ACTION_TYPES.URL ? button.url : button.phone_number, onChangeHandler: (value) => {
                                        handleButtonTextChange(value, i, RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION, button.type === CALL_TO_ACTION_TYPES.URL ? "url" : "phone_number");
                                    }, placeholder: t(button.type === CALL_TO_ACTION_TYPES.URL ? "url" : "phoneNumber"), disabled: this.blockField(), maxLength: 2000, size: "large" })))))),
                    callToActionButtons && callToActionButtons.length < callToActionButtonLimit && (React.createElement(CommonButton, { disabled: this.blockField(), action: () => handleAddButton(RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION, callToActionButtons[0].type), additionalStyles: s.addButton },
                        React.createElement(Icon, { component: GreenPlus })))))),
            React.createElement("div", { className: s.previewWrapper },
                React.createElement(MessagePreview, { header: {
                        type: richTemplateHeaderType,
                        text: richTemplateHeader,
                        file: richTemplateHeader,
                        url: richTemplateHeaderUrl,
                    }, body: {
                        text: templateMessage,
                    }, footer: {
                        type: WhatsApp.TemplateComponentType.FOOTER,
                        text: richTemplateFooter || '',
                    }, buttons: Object.assign(Object.assign({}, quickReplyButtons && {
                        type: RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY,
                        buttons: quickReplyButtons,
                    }), callToActionButtons && {
                        type: RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION,
                        buttons: callToActionButtons,
                    }) }),
                React.createElement(CommonButton, { disabled: this.blockMessagePreviewField() || (!hasSampleParamsSetup && !hasFile), onMouseEnter: () => this.setState({ fillButton: true }), onMouseLeave: () => this.setState({ fillButton: false }), action: this.switchOpenSampleDataModal },
                    React.createElement("div", { className: classnames(s.previewButtonContent, {
                            [s.rtl]: getDirection() === 'rtl',
                        }) },
                        React.createElement(Icon, { className: classnames({ [s.iconFill]: fillButton }), component: hasAtLeastOneSampleParamAdded ? EditIcon : Plus }),
                        React.createElement(Text, { className: classnames({ [s.previewTextFill]: fillButton }) },
                            React.createElement(Trans, { i18nKey: this.templateIsApproved()
                                    ? 'viewSampleData'
                                    : hasAtLeastOneSampleParamAdded
                                        ? 'editSampleData'
                                        : 'addSampleData' })))),
                !hasAllSampleParamsAdded && !this.templateIsApproved() && (React.createElement("div", { className: s.sampleDataErrorContainer },
                    React.createElement(ExclamationCircleOutlined, { className: s.errorCircle }),
                    React.createElement(Trans, { i18nKey: "provideSampleData" }))),
                React.createElement(MessagePreviewModal, { setModalVisible: this.switchOpenSampleDataModal, visible: sampleDataModalIsOpen, disabled: this.blockField() }))));
    }
}
export default withTranslation()(withStore(observer(RichParams)));
