import React, { Component, createRef } from "react";
import { observer } from "mobx-react";
import { Trans } from "react-i18next";
import classnames from "classnames";
import Icon, { ExclamationCircleOutlined, FileOutlined, PaperClipOutlined } from "@ant-design/icons";
import Files from "react-files";
import { Button } from "antd";
import { withStore } from "../../../../../../../store/rootStore";
import { ReactComponent as CsvBucket } from "../../../../../../../assets/csvFilesAndIcons/csvBucket.svg";
import { MyTextArea } from "../../../../../../Base/Form";
import styles from "./CustomReply.module.scss";
class CustomReply extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "replyRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "attachFileButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "handleAddEmoji", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (emoji, start, end) => {
                this.props.store.templateMessagesStore.addEmojiToReply(emoji, start, end);
            }
        });
        Object.defineProperty(this, "onDocSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files) => {
                this.setState({ fileLoadError: false });
                this.props.store.templateMessagesStore.setAttachedFile(files[files.length - 1]);
            }
        });
        Object.defineProperty(this, "onFilesError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error, file) => {
                console.log(error);
                this.setState({ fileLoadError: true });
            }
        });
        this.state = {
            isAssignModalVisible: false,
            createButtonActive: false,
            openEmoji: false,
            textboxActive: false,
            fileLoadError: false,
            selectionStart: props.store.templateMessagesStore.templateReplyText.length,
            selectionEnd: props.store.templateMessagesStore.templateReplyText.length,
        };
        this.replyRef = createRef();
        this.attachFileButtonRef = createRef();
    }
    render() {
        const { store, canAttachFile } = this.props;
        const { attachedFile, templateReplyText, handleSetReplyText, setAttachedFile } = store.templateMessagesStore;
        const { fileLoadError } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("label", { className: styles.label },
                React.createElement(Trans, { i18nKey: "replyText" })),
            React.createElement(MyTextArea, { placeholderText: "enterText", text: templateReplyText, setText: handleSetReplyText, align: "bottom", emojiPicker: true }),
            canAttachFile && (React.createElement(Files, { className: classnames(styles.attachFileFilesDropzone, {
                    [styles.attachFileLoaded]: attachedFile && !fileLoadError,
                }), onChange: this.onDocSelected, onError: this.onFilesError, accepts: [".pdf", ".doc", ".docx"], multiple: false, clickable: true },
                React.createElement(Button, { ref: this.attachFileButtonRef, className: classnames(styles.attachFileToReplyButton, { [styles.attachFileChosen]: attachedFile }), onClick: () => {
                        var _a;
                        (_a = this.attachFileButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                    } },
                    React.createElement("div", null,
                        !attachedFile && !fileLoadError ? (React.createElement(PaperClipOutlined, { className: styles.attachFileIcon })) : (React.createElement(FileOutlined, { className: styles.attachFileIcon })),
                        !attachedFile && !fileLoadError && React.createElement(Trans, { i18nKey: "attachFile" }),
                        attachedFile && !fileLoadError && attachedFile.name,
                        fileLoadError && React.createElement(Trans, { i18nKey: "retry" })),
                    attachedFile && !fileLoadError && (React.createElement(Icon, { className: styles.deleteCsvFile, onClick: (e) => {
                            e.stopPropagation();
                            setAttachedFile(null);
                        }, component: CsvBucket }))))),
            React.createElement("div", { className: styles["button-help-text"] },
                React.createElement(ExclamationCircleOutlined, { className: styles["info-circle"] }),
                React.createElement("p", { className: styles["info-text"] },
                    React.createElement(Trans, { i18nKey: canAttachFile ? "recommendedFileSize" : "attachWillBeAvailableWhenSending" })))));
    }
}
export default withStore(observer(CustomReply));
