import { Collapse } from 'antd';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Trans } from 'react-i18next';
import s from './Collapsable.module.scss';
const { Panel } = Collapse;
const Collapsable = ({ title, icon, routes, goToAppPath }) => {
    const onLinkClick = (link) => {
        var _a, _b;
        if (link.url) {
            window.open(link.url, (_a = link.target) !== null && _a !== void 0 ? _a : '_blank', typeof link.windowFeatures === 'boolean'
                ? undefined
                : (_b = link.windowFeatures) !== null && _b !== void 0 ? _b : 'noopener noreferrer');
        }
        else if (link.path) {
            goToAppPath === null || goToAppPath === void 0 ? void 0 : goToAppPath(link.path);
        }
        else if (link.onClick) {
            link.onClick();
        }
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(Collapse, { expandIconPosition: "end", bordered: false },
            React.createElement(Panel, { header: React.createElement(List, null,
                    React.createElement(ListItem, { button: true },
                        React.createElement("div", { className: s.panelIcon },
                            React.createElement(ListItemIcon, null, icon)),
                        React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: title }) }))), key: "1" },
                React.createElement("div", { className: s.content }, routes.map((link, index) => (React.createElement(List, { key: index },
                    React.createElement(ListItem, { button: true, onClick: () => onLinkClick(link), disabled: link.disabled },
                        React.createElement(ListItemText, { primary: React.createElement("div", { className: s.title },
                                link.icon,
                                React.createElement(Trans, { i18nKey: link.titleKey })) }))))))))));
};
export default Collapsable;
