import React, { useEffect, useState } from 'react';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { ReactComponent as ZapierIcon } from '../../../assets/icons/leftMenuIcons/zapier.svg';
import { ReactComponent as MakeIcon } from '../../../assets/icons/leftMenuIcons/make.svg';
import { GrConfigure, GrInstagram } from 'react-icons/gr';
import Collapsable from './Collapsable';
import { getConfig } from '../../../settings';
const staticRoutes = [
    {
        titleKey: 'apiTokensManager',
        icon: React.createElement(VpnKeyOutlinedIcon, null),
        path: '/manage/api-tokens',
    },
    {
        titleKey: 'oauthManager',
        icon: React.createElement(AccountTreeOutlinedIcon, null),
        path: '/manage/oauth',
    },
    {
        titleKey: 'panelZapier',
        icon: React.createElement(ZapierIcon, null),
        url: 'https://zapier.com/developer/public-invite/188308/637c625ce8e4d609634e8b16540f27ce',
    },
    {
        titleKey: 'panelMake',
        icon: React.createElement(MakeIcon, null),
        url: 'https://www.make.com/en/hq/app-invitation/2aec0eb04e88d1fb86a5ec560692d365',
    },
];
const DevelopersSettings = ({ goToAppPath }) => {
    const [inboxConfig, setInboxConfig] = useState(null);
    useEffect(() => {
        getConfig()
            .then((config) => {
            setInboxConfig(config);
        })
            .catch((error) => {
            console.error('Error getting inbox config', error);
        });
    }, []);
    let routes = [...staticRoutes];
    if (inboxConfig === null || inboxConfig === void 0 ? void 0 : inboxConfig.instagramBusinessLogin) {
        const queryParams = new URLSearchParams();
        queryParams.set('state', btoa(JSON.stringify({ projectId: inboxConfig.projectId })));
        queryParams.set('enable_fb_login', '1');
        queryParams.set('force_authentication', '1');
        queryParams.set('response_type', 'code');
        queryParams.set('redirect_uri', inboxConfig.instagramBusinessLogin.redirectUrl);
        queryParams.set('client_id', inboxConfig.instagramBusinessLogin.clientId);
        queryParams.set('scope', 'instagram_business_basic instagram_business_manage_messages instagram_business_manage_comments');
        const url = `https://www.instagram.com/oauth/authorize?${queryParams.toString()}`;
        console.debug('Instagram login URL:', url);
        routes.unshift({
            titleKey: 'panelConnectInstagram',
            icon: React.createElement(GrInstagram, null),
            url,
            target: '_self',
            windowFeatures: false,
        });
    }
    return React.createElement(Collapsable, { title: "panelDevelopers", icon: React.createElement(GrConfigure, null), routes: routes, goToAppPath: goToAppPath });
};
export default DevelopersSettings;
