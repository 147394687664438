import { SearchOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { HiOutlineChat } from "react-icons/hi";
import HighlightedText from "../Base/Utils/HighlightedText";
import LazyLoadList from "../Base/Utils/LazyLoadList";
import CommonButton from "../Chat/common/CommonButton";
import { ReactComponent as CalendarIcon } from "../../assets/icons/noGroupIcons/calendar-check-line.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/leftMenuIcons/reports-circle.svg";
import { ReactComponent as CrossRedIcon } from "../../assets/messageStatuses/crossRed.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock/pending-clock.svg";
import { MyInput } from "../Base/Form";
import LoadingScreen from "../LoadingScreen";
import s from "./BulkReportsDrawer.module.scss";
const { RangePicker } = DatePicker;
const ReportsSelector = ({ searchValue, setSearchValue, dateRangeValue, setDateRangeValue, reportsList, selectedReportId, selectReport, onLoadMore, onSendNewBulkMessage, hasMore, loading, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s["reports-selector__container"] },
        React.createElement("div", { className: s["reports-selector__inputsContainer"] },
            React.createElement("div", { className: s["reports-selector__inputsWrapper"] },
                React.createElement(MyInput, { type: "search", size: "large", placeholder: t("searchByName"), value: searchValue, onChangeHandler: (value) => setSearchValue(value), prefix: React.createElement(SearchOutlined, { className: s["reports-selector__inputIcon"] }), allowClear: {
                        clearIcon: React.createElement(CrossRedIcon, { className: s["reports-selector__inputIcon--clear"] }),
                    }, fullWidth: false })),
            React.createElement("div", { className: s["reports-selector__inputsWrapper"] },
                React.createElement(RangePicker, { value: dateRangeValue, format: "MMM Do YY", picker: "date", placeholder: [t("startDate"), t("endDate")], className: s["reports-selector__datePicker"], dropdownClassName: s["reports-selector__datePickerDropdown"], onChange: setDateRangeValue, suffixIcon: React.createElement(CalendarIcon, { className: s["reports-selector__inputIcon"] }), allowClear: false }),
                React.createElement(CrossRedIcon, { onClick: () => setDateRangeValue(null), style: { display: dateRangeValue[0] !== null ? "block" : "none" }, className: s["reports-selector__inputIcon--clear"] }))),
        loading ? (React.createElement("div", { className: s["report-selector__loadingContainer"] },
            React.createElement(LoadingScreen, null))) : reportsList.length > 0 ? (React.createElement(LazyLoadList, { loadingNext: loading, hasNext: hasMore, onLoadNext: onLoadMore, className: s['reports-selector__listContainer'] }, reportsList.map(({ title, templateTitle, templateName, date, _id }) => (React.createElement("li", { key: _id, className: classnames(s['reports-selector__listItem'], {
                [s['reports-selector__listItem--selected']]: _id === selectedReportId,
            }), onClick: () => selectReport(_id) },
            moment(date).isAfter(moment()) ? (React.createElement(ClockIcon, { className: classnames(s['reports-selector__clockIcon'], {
                    [s['reports-selector__listIcon--selected']]: _id === selectedReportId,
                }) })) : (React.createElement(ReportsIcon, { className: classnames(s['reports-selector__listIcon'], {
                    [s['reports-selector__listIcon--selected']]: _id === selectedReportId,
                }) })),
            React.createElement("div", { className: classnames(s['reports-selector__titleContainer'], {
                    [s['reports-selector__titleContainer--selected']]: _id === selectedReportId,
                }) },
                React.createElement("div", { className: s['reports-selector__listItemTitle'] },
                    React.createElement(HighlightedText, { text: title || templateTitle, highlighted: searchValue })),
                React.createElement("div", { className: s['reports-selector__listItemSubTitle'] }, templateName)),
            React.createElement("div", { className: s['reports-selector__listItemDate'] },
                moment(date).isAfter(moment()) && (React.createElement("div", { className: s['reports-selector_scheduledLabel'] },
                    t('scheduled'),
                    ":")),
                date ? moment(date).format('D MMM YYYY') : '')))))) : (React.createElement("div", { className: s['reports-selector__noReports'] },
            React.createElement("div", { className: s['reports-selector__noReports--title'] }, t('noBulkCampaignsYet')),
            React.createElement(CommonButton, { action: onSendNewBulkMessage, additionalStyles: s['reports-selector__noReports--button'] },
                React.createElement(HiOutlineChat, null),
                t('sendNewBulkMessage'))))));
};
export default ReportsSelector;
