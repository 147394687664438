import React from 'react';
import StoryReply from './components/StoryReply';
import StoryMention from './components/StoryMention';
import Comment from './components/Comment';
import { useStore } from '../../../../../../store/rootStore';
import InstagramMessageLabel from './components/InstagramMessageLabel';
import styles from './InstagramMessage.module.scss';
const InstagramMessage = ({ message, direction }) => {
    const { activeChatStore: { chat }, } = useStore();
    return (React.createElement("div", { className: styles.messageContainer },
        React.createElement(InstagramMessageLabel, { message: message, direction: direction, chatTitle: chat === null || chat === void 0 ? void 0 : chat.title }),
        message.storyMention && React.createElement(StoryMention, { storyMention: message.storyMention, direction: direction }),
        message.storyReply && React.createElement(StoryReply, { storyReply: message.storyReply }),
        message.comment && React.createElement(Comment, { comment: message.comment })));
};
export default InstagramMessage;
