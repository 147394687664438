import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Typography, Tooltip } from "antd";
import { CheckOutlined, MoreOutlined } from "@ant-design/icons";
import classnames from "classnames";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import ReactionsChart, { randomColors } from "./ReactionsChart";
import LoadingIcon from "../Chat/common/LoadingIcon";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { BulkReportContactDataOptions } from "../../store/BulkReportsStore";
import { ReactComponent as ReportsIcon } from "../../assets/icons/leftMenuIcons/reports-circle.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download/file-download.svg";
import { formatAsPercent } from "./helpers";
import { withStore } from "../../store/rootStore";
import SubmitCancelModal from "../Chat/QuickReplies/TemplateGenerator/AddOrEditTemplate/SubmintCancelModal";
import { MESSAGE_STATUS } from "@whatsper/texterchat-common";
import { MyInput } from "../Base/Form";
import { getDirection } from "../../helpers";
import s from "./BulkReportsDrawer.module.scss";
const { Text, Paragraph } = Typography;
const SingleReport = ({ report, onUpdateComment, onUpdateTitle, loading, downloadAllDataLoading, onDownloadContacts, onDownloadAllData, store, }) => {
    if (loading) {
        return (React.createElement("div", { className: classnames(s["single-report__container"], s["single-report__container--center"]) },
            React.createElement(LoadingIcon, { showInfoMessageForLongLoadingTime: true })));
    }
    if (!report) {
        return (React.createElement("div", { className: classnames(s["single-report__container"], s["single-report__container--center"]) },
            React.createElement(ReportsIcon, null)));
    }
    const commentTextAreaRef = useRef(null);
    const [allowCommentEdit, setAllowCommentEdit] = useState(false);
    const [editableComment, setEditableComment] = useState(report.comment);
    const [editableTitle, setEditableTitle] = useState(report.title || report.templateTitle);
    const [showEditTitleInput, setShowEditTitleInput] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { t } = useTranslation();
    const scheduledReport = store.bulkReports.scheduledReportsList.find(({ _id }) => _id === report._id);
    const { sent, skipped, failed, unsubscribed, templateTitle, templateName, title, date, reactions, comment, totalClients, messageStatus: { [MESSAGE_STATUS.MESSAGE_DELIEVERED]: delivered = 0, [MESSAGE_STATUS.MESSAGE_SEEN]: viewed = 0 }, } = report;
    const totalDelivered = delivered + viewed;
    useEffect(() => {
        setEditableComment(comment);
    }, [comment]);
    useEffect(() => {
        setEditableTitle(title || templateTitle);
    }, [title, templateTitle]);
    const onChangeComment = (value) => {
        setEditableComment(value);
    };
    const onChangeTitle = (title) => {
        onUpdateTitle(title);
        setEditableTitle(title);
        setShowEditTitleInput(false);
    };
    const onSaveComment = () => {
        var _a;
        if (allowCommentEdit) {
            onUpdateComment(editableComment || '');
        }
        else {
            (_a = commentTextAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        setAllowCommentEdit(!allowCommentEdit);
    };
    const onCancelComment = () => {
        setEditableComment(comment);
        setAllowCommentEdit(false);
    };
    const openRenameCampaign = () => {
        setShowEditTitleInput(true);
    };
    const closeRenameCampaign = () => {
        setShowEditTitleInput(false);
    };
    const onDownloadCSV = (type) => {
        if (type === BulkReportContactDataOptions.DELIVERED && totalDelivered === 0)
            return;
        if (type === BulkReportContactDataOptions.VIEWED && viewed === 0)
            return;
        if (type === BulkReportContactDataOptions.UNSUBSCRIBED && unsubscribed === 0)
            return;
        onDownloadContacts(type);
    };
    const onCancelScheduledMessage = () => {
        if (scheduledReport) {
            store.bulkReports.deleteDatabaseTasksByBulkId(scheduledReport._id);
        }
        setShowCancelModal(false);
    };
    const menu = () => {
        const items = [
            {
                key: "downloadData",
                label: t("downloadData"),
                onClick: onDownloadAllData,
                disabled: totalDelivered === 0 || downloadAllDataLoading,
                icon: downloadAllDataLoading ? React.createElement(LoadingIcon, { additionalStyles: s["download-all__loading--icon"] }) : null,
                className: s["download-all__container"],
            },
            {
                key: "rename",
                label: t("rename"),
                onClick: openRenameCampaign,
                icon: React.createElement(MdOutlineDriveFileRenameOutline, null)
            },
            ...(!!scheduledReport
                ? [
                    {
                        key: "cancelScheduledMessage",
                        label: t("cancelScheduledMessage"),
                        onClick: () => setShowCancelModal(true),
                    },
                ]
                : []),
        ];
        return Object.assign({ items }, getDirection() === "rtl" && { style: { direction: "rtl" } });
    };
    return (React.createElement("div", { className: s["single-report__container"] },
        React.createElement("div", { className: s["single-report__titleContainer"] },
            React.createElement("div", { className: s["single-report__titleContentWrapper"] },
                React.createElement("div", { className: s["single-report__subTitle"] }, templateName),
                React.createElement(Paragraph, { className: classnames(s["single-report__title"], {
                        [s.rtl]: getDirection() === "rtl" && showEditTitleInput,
                    }), editable: {
                        onChange: onChangeTitle,
                        editing: showEditTitleInput,
                        icon: React.createElement("div", null),
                        enterIcon: React.createElement("div", null),
                    } }, editableTitle),
                React.createElement(CheckOutlined, { style: { display: showEditTitleInput ? "block" : "none" }, onClick: closeRenameCampaign, className: classnames(s["single-report__enterIcon"], {
                        [s.rtl]: getDirection() === "rtl",
                    }) }),
                React.createElement("div", { className: s["single-report__date"] }, scheduledReport ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: s["reports-selector_scheduledLabel"] }, t("scheduledToBeSent")),
                    React.createElement("div", null, moment(scheduledReport.date).format("D MMM YYYY, ddd, h:mma")))) : date ? (moment(date).format("D MMM YYYY, ddd, h:mma")) : (""))),
            React.createElement(Dropdown, { trigger: ["click"], placement: "bottomRight", menu: menu() },
                React.createElement(MoreOutlined, { size: 32, className: s["single-report__showMoreButton"] })),
            React.createElement("div", { className: classnames(s["single-report__greenBorder"], {
                    [s.rtl]: getDirection() === "rtl",
                }) })),
        React.createElement("div", { className: classnames(s["single-report__infoLine"], [
                s["single-report__infoLine--not-clickable"],
                [s["single-report__infoLine--scheduled"]],
            ]) },
            React.createElement(Text, { className: s['single-report__infoLineText'] }, scheduledReport ? t('templateWillBeSentTo') : t('templateWasSentTo')),
            React.createElement(Text, { className: classnames(s["single-report__infoLineText"], s["single-report__infoLineText--value"], s["infoLineText--bold"], s["infoLineText--spacing"]) },
                React.createElement("div", null, totalClients),
                React.createElement("div", null, t("users")))),
        React.createElement("div", { className: classnames(s["single-report__infoContainer"], {
                [s["single-report__infoContainer--scheduled"]]: !!scheduledReport,
            }) },
            React.createElement("div", { className: classnames(s["single-report__infoLine"], s["single-report__infoLine--not-clickable"]) },
                React.createElement(Text, { className: s["single-report__infoLineText"] },
                    React.createElement(Trans, { i18nKey: "totalSent" })),
                React.createElement(Text, { className: s["single-report__infoLineText"] }, sent)),
            React.createElement(Tooltip, Object.assign({ title: React.createElement(Trans, { i18nKey: "downloadCSV" }), placement: "topLeft", align: { offset: [-35, 0] } }, (totalDelivered === 0 && { visible: false })),
                React.createElement("div", { onClick: () => onDownloadCSV(BulkReportContactDataOptions.DELIVERED), className: classnames(s["single-report__infoLine"], {
                        [s["single-report__infoLine--not-clickable"]]: totalDelivered === 0,
                    }) },
                    React.createElement(DownloadIcon, null),
                    React.createElement(Text, { className: s["single-report__infoLineText"] }, t("totalDelivered")),
                    React.createElement(Text, { className: s["single-report__infoLineText"] },
                        React.createElement("div", null, totalDelivered),
                        React.createElement("div", null, "/"),
                        React.createElement("div", { className: classnames(s["single-report__infoLineText--value"], s["infoLineText--bold"], s["infoLineText--spacing"]) }, formatAsPercent((totalDelivered * 100) / sent))))),
            React.createElement(Tooltip, Object.assign({ title: React.createElement(Trans, { i18nKey: "downloadCSV" }), placement: "topLeft", align: { offset: [-35, 0] } }, (viewed === 0 && { visible: false })),
                React.createElement("div", { onClick: () => onDownloadCSV(BulkReportContactDataOptions.VIEWED), className: classnames(s["single-report__infoLine"], {
                        [s["single-report__infoLine--not-clickable"]]: viewed === 0,
                    }) },
                    React.createElement(DownloadIcon, null),
                    React.createElement(Text, { className: s["single-report__infoLineText"] }, t("totalView")),
                    React.createElement(Text, { className: s["single-report__infoLineText"] },
                        React.createElement("div", null, viewed),
                        React.createElement("div", null, "/"),
                        React.createElement("div", { className: classnames(s["single-report__infoLineText--value"], s["infoLineText--spacing"], {
                                [s["infoLineText--positive"]]: true,
                            }) }, formatAsPercent((viewed * 100) / sent))))),
            React.createElement("div", { className: classnames(s["single-report__infoLine"], s["single-report__infoLine--not-clickable"]) },
                React.createElement(Text, { className: s["single-report__infoLineText"] }, t("skipped")),
                React.createElement(Text, { className: s["single-report__infoLineText"] }, skipped)),
            React.createElement("div", { className: classnames(s["single-report__infoLine"], s["single-report__infoLine--not-clickable"]) },
                React.createElement(Text, { className: s["single-report__infoLineText"] }, t("failed")),
                React.createElement(Text, { className: s["single-report__infoLineText"] }, failed)),
            React.createElement(Tooltip, Object.assign({ title: React.createElement(Trans, { i18nKey: "downloadCSV" }), placement: "topLeft", align: { offset: [-35, 0] } }, (unsubscribed === 0 && { visible: false })),
                React.createElement("div", { onClick: () => onDownloadCSV(BulkReportContactDataOptions.UNSUBSCRIBED), className: classnames(s["single-report__infoLine"], {
                        [s["single-report__infoLine--not-clickable"]]: unsubscribed === 0,
                    }) },
                    React.createElement(DownloadIcon, null),
                    React.createElement(Text, { className: s["single-report__infoLineText"] }, t("unsubscribed")),
                    React.createElement(Text, { className: s["single-report__infoLineText"] },
                        React.createElement("div", null, unsubscribed),
                        React.createElement("div", null, "/"),
                        React.createElement("div", { className: classnames(s["single-report__infoLineText--value"], s["infoLineText--bold"], s["infoLineText--spacing"], {
                                [s["infoLineText--negative"]]: true,
                            }) }, formatAsPercent((unsubscribed * 100) / sent)))))),
        React.createElement("div", { className: s.bulkPercentageNote },
            React.createElement(Trans, { i18nKey: "bulkPercentageNote", components: { b: React.createElement("b", null) } })),
        reactions && reactions.length > 0 && (React.createElement("div", { className: s["single-report__reactionsContainer"] },
            React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "downloadCSV" }), placement: "topLeft", align: { offset: [-35, 0] } },
                React.createElement("div", { onClick: () => onDownloadCSV(BulkReportContactDataOptions.REACTIONS), className: classnames(s["single-report__infoLine"], s["single-report__infoLine--reactions"]) },
                    React.createElement(DownloadIcon, null),
                    React.createElement("div", { className: s["single-report__reactionsTitle"] },
                        t("reactions"),
                        ":"))),
            React.createElement("div", { className: s["single-report__contentContainer"] },
                React.createElement(ReactionsChart, { reactions: reactions }),
                React.createElement("div", { className: classnames(s["single-report__infoContainer"], s["single-report__infoContainer--no-margin"]) }, reactions.map(({ title, count }, i) => (React.createElement("div", { key: title, className: classnames(s["single-report__infoLine"], s["single-report__infoLine--not-clickable"]) },
                    React.createElement(Text, { className: s["single-report__infoLineText"] },
                        React.createElement("div", { className: s["single-report__infoLineText__colorBox"], style: { backgroundColor: randomColors[i] } }),
                        title),
                    React.createElement(Text, { className: s["single-report__infoLineText"] },
                        React.createElement("div", null, count),
                        React.createElement("div", null, "/"),
                        React.createElement("div", { className: classnames(s["single-report__infoLineText--value"], s["infoLineText--bold"], s["infoLineText--spacing"]) }, formatAsPercent((count * 100) / sent)))))))))),
        React.createElement("div", { className: s["single-report__commentContainer"] },
            React.createElement("label", { className: s["single-report__commentLabel"] }, t("Comment")),
            React.createElement(MyInput.TextArea, { value: editableComment, onChange: onChangeComment, className: s["single-report__commentTextArea"], autoSize: { minRows: 4, maxRows: 5 }, readOnly: !allowCommentEdit, forwardRef: commentTextAreaRef, bordered: true }),
            React.createElement("div", { className: s["single-report__commentButtons"] },
                React.createElement(Button, { type: "default", className: classnames(s["single-report__commentButton"], s["single-report__commentButton--defaultButton"], "comment-button"), onClick: onCancelComment }, t("cancel")),
                React.createElement(Button, { type: "primary", className: classnames("big-green-button", s["single-report__commentButton"], s["single-report__commentButton--primaryButton"], "comment-button"), onClick: onSaveComment }, allowCommentEdit ? t("save") : t("edit")))),
        React.createElement(SubmitCancelModal, { visible: showCancelModal, onSubmit: onCancelScheduledMessage, onCancel: () => setShowCancelModal(false), i18key: "cancelScheduledMessageInfo" })));
};
export default withStore(SingleReport);
