import React, { useState } from 'react';
import { Dropdown, Typography, Radio, Divider, Tooltip, Button } from 'antd';
import Icon, { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { AiOutlineSetting, AiOutlineShareAlt, AiOutlineUserSwitch } from 'react-icons/ai';
import { RiLinkM, RiMailCheckLine, RiMailCloseLine } from 'react-icons/ri';
import { ReactComponent as Star } from '../../../../assets/icons/chatStatuses/star.svg';
import { ReactComponent as YellowStar } from '../../../../assets/icons/chatStatuses/yellowStar.svg';
import { ReactComponent as LockClosed } from '../../../../assets/icons/lock/lockClosed.svg';
import { ReactComponent as LockOpen } from '../../../../assets/icons/lock/lockOpen.svg';
import { AfterResolveChatAction } from '../../../../constants';
import { getDirection } from '../../../../helpers';
import styles from '../UserInfo.module.scss';
const { Text } = Typography;
/**
 * Dropdown menu containing chat actions hidden under the three dots "see more" icon.
 * Number of items and their order is different for mobile and desktop.
 *
 * @param {boolean} isMobile - Whether the dropdown menu is displayed on mobile or desktop.
 * @param {boolean} blocked - Whether the user is blocked.
 * @param {boolean} important - Whether the chat is marked as important.
 * @param {boolean} unsubscribed - Whether the user is unsubscribed from TM.
 * @param {() => void} markAsImportant - Function to mark the chat as important.
 * @param {() => void} showSubscribeModal - Function to show the subscribe modal.
 * @param {() => void} onBlockUserClick - Function to block the user.
 * @param {() => void} onShareChatClick - Function to share the chat.
 * @param {() => void} onShowFilesMediaLinks - Sidebar Module: Show the files, media, and links.
 * @param {() => void} onSearchInChat - Sidebar Module: Show In-Chat Search
 * @param {MobileProps} mobileProps - Mobile specific props. {@link MobileProps}
 *
 * @returns {ReactElement} Dropdown menu component.
 *
 */
const DropdownMenu = ({ isMobile, blocked, important, unsubscribed, markAsImportant, showSubscribeModal, onBlockUserClick, onShowFilesMediaLinks, onSearchInChat, onShareChatClick, mobileProps, }) => {
    const [assignMenuOpen, setAssignMenuOpen] = useState(false);
    const commonItems = [
        {
            key: 'searchInChat',
            icon: React.createElement(Icon, { className: styles.menuIcon, component: SearchOutlined }),
            label: React.createElement(Trans, { i18nKey: "searchInChat" }),
            onClick: onSearchInChat,
        },
        {
            key: 'filesMediaLinks',
            icon: React.createElement(Icon, { className: styles.menuIcon, component: RiLinkM }),
            label: React.createElement(Trans, { i18nKey: "filesMediaLinks" }),
            onClick: onShowFilesMediaLinks,
        },
        {
            key: 'shareChat',
            icon: React.createElement(AiOutlineShareAlt, null),
            label: React.createElement(Trans, { i18nKey: "shareChat" }),
            onClick: onShareChatClick,
        },
        {
            type: 'divider',
        },
        {
            key: 'markAsImportant',
            icon: React.createElement(Icon, { className: styles.menuIcon, component: important ? YellowStar : Star }),
            label: React.createElement(Trans, { i18nKey: important ? 'unmarkAsImportant' : 'markAsImportant' }),
            onClick: markAsImportant,
        },
        {
            type: 'divider',
        },
        {
            key: 'unsubscribeFromTM',
            icon: React.createElement(Icon, { className: styles.menuIcon, component: unsubscribed ? RiMailCheckLine : RiMailCloseLine }),
            label: React.createElement(Trans, { i18nKey: unsubscribed ? 'resubscribeToTM' : 'unsubscribeFromTM' }),
            onClick: showSubscribeModal,
        },
        {
            key: 'blockUser',
            icon: (React.createElement(Icon, { className: classNames(styles.menuIcon, styles.errorIcon), component: blocked ? LockOpen : LockClosed })),
            label: (React.createElement(Text, { className: styles.alertText },
                React.createElement(Trans, { i18nKey: blocked ? 'unblockUser' : 'blockUser' }))),
            onClick: onBlockUserClick,
        },
    ];
    const { onAfterResolveActionChange, afterResolveAction, toggleSelectBotModal, resolveChat, selectedBotNode, disabled, } = mobileProps.resolveChatProps;
    const mobileItems = [
        {
            key: 'markAsSolved',
            icon: React.createElement(CheckCircleOutlined, null),
            disabled,
            label: (React.createElement("div", { className: classNames(styles.markAsSolvedMobileMenuContainer, {
                    [styles.disabled]: disabled,
                }) },
                React.createElement("div", { onClick: () => {
                        if (disabled)
                            return;
                        resolveChat();
                        setAssignMenuOpen(false);
                    } },
                    React.createElement("div", { className: styles.radioOptionBoldText },
                        React.createElement(Trans, { i18nKey: "resolveChat" })),
                    afterResolveAction && afterResolveAction !== AfterResolveChatAction.NONE && (React.createElement("div", { className: styles.radioOptionSubText }, afterResolveAction === AfterResolveChatAction.RUN_BOT ? (getDirection() === 'rtl' ? (React.createElement(Trans, { i18nKey: "runBotRTL", values: { botNode: selectedBotNode } })) : (React.createElement(Trans, { i18nKey: "runBot", values: { botNode: selectedBotNode } }))) : (React.createElement(Trans, { i18nKey: afterResolveAction, values: { botNode: selectedBotNode } }))))),
                React.createElement(Button, { type: "ghost", size: "small", disabled: disabled, icon: assignMenuOpen ? React.createElement(CaretUpOutlined, null) : React.createElement(CaretDownOutlined, null), onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (disabled)
                            return;
                        setAssignMenuOpen(!assignMenuOpen);
                    } }))),
            onClick: () => {
                resolveChat();
                setAssignMenuOpen(false);
            },
        },
        ...(assignMenuOpen
            ? [
                {
                    key: 'markAsSolvedOptions',
                    className: styles.assignToMobileMenuContainer,
                    onClick: (e) => {
                        e.domEvent.preventDefault();
                    },
                    label: (React.createElement(Radio.Group, { onChange: (e) => onAfterResolveActionChange(e.target.value), value: !mobileProps.sessionIsOver ? afterResolveAction : AfterResolveChatAction.NONE },
                        React.createElement(Radio, { className: styles.radioOption, value: AfterResolveChatAction.NONE },
                            React.createElement("span", null,
                                React.createElement("div", { className: styles.radioOptionBoldText },
                                    React.createElement(Trans, { i18nKey: "resolveChat" })))),
                        React.createElement(Divider, { className: styles.radioDivider }),
                        React.createElement(Radio, { className: styles.radioOption, disabled: mobileProps.sessionIsOver, value: AfterResolveChatAction.SEND_CLOSING_MESSAGE },
                            React.createElement("div", { className: styles.radioOptionBoldText },
                                React.createElement(Text, { className: classNames(styles.radioOptionText, {
                                        [styles.disabled]: mobileProps.sessionIsOver,
                                    }) },
                                    React.createElement(Trans, { i18nKey: "resolveChat" }))),
                            React.createElement("div", { className: classNames(styles.radioOptionSubText, {
                                    [styles.buttonGreyText]: disabled,
                                }) },
                                React.createElement(Trans, { i18nKey: 'sendClosingMessage' }))),
                        React.createElement(Divider, { className: styles.radioDivider }),
                        React.createElement(Radio, { className: styles.radioOption, disabled: mobileProps.sessionIsOver, value: AfterResolveChatAction.RUN_BOT },
                            React.createElement("span", { className: styles.withBotContainer },
                                React.createElement("div", null,
                                    React.createElement("div", { className: styles.radioOptionBoldText },
                                        React.createElement(Text, { className: classNames(styles.radioOptionText, {
                                                [styles.disabled]: mobileProps.sessionIsOver,
                                            }) },
                                            React.createElement(Trans, { i18nKey: "resolveChat" }))),
                                    React.createElement("div", { className: classNames(styles.radioOptionSubText, {
                                            [styles.buttonGreyText]: disabled,
                                        }) }, getDirection() === 'rtl' ? (React.createElement(Trans, { i18nKey: 'runBotRTL', values: { botNode: selectedBotNode } })) : (React.createElement(Trans, { i18nKey: 'runBot', values: { botNode: selectedBotNode } })))),
                                React.createElement(Tooltip, { title: !mobileProps.sessionIsOver ? React.createElement(Trans, { i18nKey: "selectBotNode" }) : '', placement: "topLeft" },
                                    React.createElement(AiOutlineSetting, { onClick: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            toggleSelectBotModal();
                                        } })))))),
                },
            ]
            : []),
        {
            key: 'assignTo',
            icon: React.createElement(AiOutlineUserSwitch, null),
            label: React.createElement(Trans, { i18nKey: "assignToDots" }),
            onClick: mobileProps.onAssignToClick,
        },
        {
            type: 'divider',
        },
    ];
    const items = isMobile ? [...mobileItems, ...commonItems] : [...commonItems];
    const dropdownMenu = { items };
    return (React.createElement(Dropdown, { trigger: ['click'], placement: getDirection() === 'rtl' ? 'bottomLeft' : 'bottomRight', menu: dropdownMenu, overlayClassName: styles.dropdownMenu, getPopupContainer: (triggerNode) => triggerNode.parentElement, onOpenChange: (open) => {
            if (!open)
                setAssignMenuOpen(false);
        } },
        React.createElement(Button, { className: classNames(styles.icon, styles.iconMore, styles.dropdownMenuButton) },
            React.createElement(BiDotsHorizontalRounded, null))));
};
export default DropdownMenu;
