import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { WhatsApp } from '@whatsper/texterchat-common';
import MainBlock from './Components/MainBlock';
import { TEMPLATE_PARTS } from '../../../../constants';
import { useStore } from '../../../../store/rootStore';
import { createPortal } from 'react-dom';
import QuickReplyButtonsBlock from './Components/QuickReplyButtonsBlock';
import CallToActionButtonsBlock from './Components/CallToActionButtonsBlock';
import styles from './RichMessage.module.scss';
const isQuickReply = (messageBlock) => {
    return (messageBlock.type === WhatsApp.TemplateComponentType.BUTTONS &&
        messageBlock.buttons[0].type === WhatsApp.TemplateButtonType.QUICK_REPLY);
};
const isCallToAction = (messageBlock) => {
    return (messageBlock.type === WhatsApp.TemplateComponentType.BUTTONS &&
        messageBlock.buttons[0].type !== WhatsApp.TemplateButtonType.QUICK_REPLY);
};
const RichMessage = ({ parameters, templateId, afterFooterRef, templateMessageData, hasError }) => {
    const [richMessage, setRichMessage] = useState([]);
    const { templateMessagesStore } = useStore();
    useEffect(() => {
        const template = templateMessagesStore.templateMessagesList.find((template) => template.name === templateId);
        if (template) {
            setRichMessage(template.obj.localizations[0].components);
        }
    }, [templateId, templateMessagesStore.templateMessagesList]);
    const parsedRichMessage = {
        header: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.HEADER),
        body: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.BODY),
        footer: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.FOOTER),
    };
    return (React.createElement("div", { className: styles.richMessageBox },
        (richMessage || templateMessageData) && (React.createElement("div", { className: styles.richMessageContainer },
            (parsedRichMessage.header || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.header)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.HEADER, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.header) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.header) })),
            (parsedRichMessage.body || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.body)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.BODY, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.body) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.body) })),
            (parsedRichMessage.footer || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.footer)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.FOOTER, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.footer) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.footer) })))),
        afterFooterRef.current &&
            createPortal(richMessage === null || richMessage === void 0 ? void 0 : richMessage.map((messageBlock, i) => (React.createElement("div", { key: i },
                isQuickReply(messageBlock) && (React.createElement(QuickReplyButtonsBlock, { messageBlock: messageBlock, error: !!hasError })),
                isCallToAction(messageBlock) && React.createElement(CallToActionButtonsBlock, { messageBlock: messageBlock })))), afterFooterRef.current)));
};
export default observer(RichMessage);
