import React from 'react';
import { MESSAGE_STATUS } from '@whatsper/texterchat-common';
import MessageMarkdown from '../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import TextMessage from '../TextMessage';
import styles from './ListMessage.module.scss';
const ListMessage = ({ text, status, list }) => {
    const renderList = () => {
        return list.sections.map(({ rows, title }, i) => {
            const shouldRenderTitle = list.sections.length > 1;
            return (React.createElement(React.Fragment, { key: i },
                shouldRenderTitle && React.createElement("span", null, title),
                React.createElement("ul", { className: styles.list }, rows.map(({ title, payload }) => (React.createElement("li", { key: payload },
                    React.createElement(MessageMarkdown, { text: title })))))));
        });
    };
    return (React.createElement("div", { dir: "auto", className: styles.listContainer },
        text && React.createElement(TextMessage, { text: text, deleted: status === MESSAGE_STATUS.MESSAGE_DELETED, outgoing: true }),
        renderList()));
};
export default ListMessage;
