var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from "mobx";
import { API_PATH } from "../../constants";
import { getAxios } from '../../backend';
import { EventName, User, chatAccess } from "@whatsper/texterchat-common";
import { omit, throttle, unionBy } from "lodash";
class AgentsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "users", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "roles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "usersSearchResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "usersStatusUnsubscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "hasPermissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (permissions) => {
                var _a, _b, _c;
                const userRole = (_b = (_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b[0];
                if (userRole && this.roles.length > 0) {
                    const userPermissions = (_c = this.roles.find(role => role.name === userRole)) === null || _c === void 0 ? void 0 : _c.permissions;
                    if (userPermissions) {
                        return permissions.every(permission => userPermissions.includes(permission));
                    }
                    return false;
                }
                return false;
            }
        });
        Object.defineProperty(this, "getRolesPermissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rolesNames) => __awaiter(this, void 0, void 0, function* () {
                const roles = this.roles.filter((role) => rolesNames.includes(role.name));
                const permissions = roles.reduce((acc, role) => {
                    return [...acc, ...role.permissions];
                }, []);
                return permissions;
            })
        });
        Object.defineProperty(this, "getUserDepartmentsIds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => {
                const departments = this.rootStore.departmentsStore.departments.filter((dep) => { var _a; return (_a = dep.agents) === null || _a === void 0 ? void 0 : _a.includes(uid); });
                return departments.map((dep) => dep._id);
            }
        });
        Object.defineProperty(this, "getAgentChatAccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat, accessType) => __awaiter(this, void 0, void 0, function* () {
                const user = this.rootStore.loginStore.userDetails;
                if (!user)
                    return false;
                return chatAccess(chat, user, accessType, this.getRolesPermissions, this.getUserDepartmentsIds);
            })
        });
        Object.defineProperty(this, "fetchUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const axios = yield getAxios();
                    const [{ data: users }, { data: statuses }] = yield Promise.all([
                        axios.get(API_PATH.USERS),
                        axios.get(`${API_PATH.USERS}/status`, {
                            params: {
                                minDateOnline: this.getMinDateOnline(),
                            }
                        }),
                    ]);
                    runInAction(() => {
                        this.users.replace(users.users.map((user) => {
                            var _a, _b;
                            return Object.assign(Object.assign({}, user), { status: ((_a = statuses[user._id]) === null || _a === void 0 ? void 0 : _a.status) || User.Status.offline, statusTime: ((_b = statuses[user._id]) === null || _b === void 0 ? void 0 : _b.last_changed) ? new Date(statuses[user._id].last_changed).getTime() : 0 });
                        }));
                    });
                }
                catch (error) {
                    console.error('ERROR: getting users failed', error);
                }
            })
        });
        Object.defineProperty(this, "searchUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query, channelName, channelAccountId) => __awaiter(this, void 0, void 0, function* () {
                const params = {
                    search: query,
                    channelName,
                    channelAccountId,
                };
                try {
                    const axios = yield getAxios();
                    const { data: result } = yield axios.get(API_PATH.CHATS, { params });
                    runInAction(() => {
                        this.usersSearchResults.replace(result.chats);
                    });
                }
                catch (error) {
                    console.error('ERROR: searching chats failed', error);
                }
            })
        });
        Object.defineProperty(this, "searchUsersThrottled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(this.searchUsers, 1000)
        });
        Object.defineProperty(this, "clearSearchUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                runInAction(() => {
                    this.usersSearchResults.replace([]);
                });
            }
        });
        Object.defineProperty(this, "createUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user, departments) => __awaiter(this, void 0, void 0, function* () {
                const request = {
                    user: user,
                    departments: departments
                };
                yield getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.USERS, request);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.users.push(Object.assign(Object.assign({}, data), { roles: ['agent'], status: User.Status.offline, statusTime: 0 }));
                    });
                });
            })
        });
        Object.defineProperty(this, "editUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user, departments) => __awaiter(this, void 0, void 0, function* () {
                const request = {
                    user: omit(user, 'uid'),
                    departments,
                };
                yield getAxios()
                    .then((axios) => {
                    return axios.patch(`${API_PATH.USERS}/${user.uid}`, request);
                })
                    .then(({ data: userUpdated }) => {
                    runInAction(() => {
                        this.users.replace(this.users.map((u) => {
                            if (u._id === user.uid) {
                                return userUpdated;
                            }
                            else {
                                return u;
                            }
                        }));
                    });
                });
            })
        });
        Object.defineProperty(this, "deleteUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.USERS}/${uid}`);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.users.replace(this.users.filter(user => user._id !== uid));
                    });
                    return data;
                });
            })
        });
        Object.defineProperty(this, "getRoles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const axios = yield getAxios();
                    const { data } = yield axios.get(API_PATH.ROLES);
                    runInAction(() => {
                        this.roles.replace(Object.values(data));
                    });
                }
                catch (error) {
                    console.error('ERROR: getting roles failed', error);
                }
            })
        });
        /**
         * Save FCM token for user.
         *
         * @todo Maybe move to another store?
         *
         * @param uid User uid
         * @param token FCM token
         */
        Object.defineProperty(this, "saveUserFCMToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid, token) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.post(`${API_PATH.USERS}/${uid}/fcm-token`, { token });
                    });
                }
                catch (error) {
                    console.error('Error saving user FCM token', error);
                }
            })
        });
        Object.defineProperty(this, "getAgentByUid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => {
                const agent = this.users.find((agent) => {
                    return uid === agent._id;
                });
                if (agent) {
                    return agent.displayName;
                }
                else
                    return "";
            }
        });
        Object.defineProperty(this, "getMinDateOnline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return Date.now()
                    - this.rootStore.generalSettings.user.logoutCountdownStart
                    - this.rootStore.generalSettings.user.logoutCountdownLength;
            }
        });
        Object.defineProperty(this, "onUsersStatusUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                this.users.replace(this.users.map((user) => {
                    if (user._id !== data.uid)
                        return user;
                    return Object.assign(Object.assign({}, user), { status: data.status, statusTime: new Date(data.last_changed).getTime() });
                }));
            }
        });
        Object.defineProperty(this, "markFavorite", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (favorites) => {
                var _a;
                const userId = (_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a._id;
                if (!userId) {
                    console.error("User ID not found");
                    return;
                }
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.USERS}/${userId}/favorites`, favorites);
                })
                    .then(({ data }) => {
                    const user = this.rootStore.loginStore.userDetails;
                    if (user) {
                        const updatedUser = Object.assign(Object.assign({}, user), { favorites: unionBy(user.favorites, favorites, 'id') });
                        this.rootStore.events.emit(EventName.user_updated, updatedUser);
                    }
                    return data.success;
                })
                    .catch((error) => {
                    console.error("Error on entering favorite", error);
                    return false;
                });
            }
        });
        Object.defineProperty(this, "unmarkFavorite", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, type) => {
                var _a;
                const userId = (_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a._id;
                if (!userId) {
                    console.error("User ID not found");
                    return;
                }
                return getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.USERS}/${userId}/favorites/${type}/${id}`);
                })
                    .then(({ data }) => {
                    const user = this.rootStore.loginStore.userDetails;
                    if (user && user.favorites) {
                        const updatedUser = Object.assign(Object.assign({}, user), { favorites: user.favorites.filter((fav) => fav.id !== id) });
                        this.rootStore.events.emit(EventName.user_updated, updatedUser);
                    }
                    return data.success;
                })
                    .catch((error) => {
                    console.error("Error on deleting entry from favorites", error);
                    return false;
                });
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.user_status_updated, (data) => this.onUsersStatusUpdated(data));
    }
    get usersMap() {
        const users = new Map();
        for (let i = 0; i < this.users.length; i++) {
            users.set(this.users[i].uid, this.users[i]);
        }
        return users;
    }
    get onlineCount() {
        return this.onlineUsersToAssignee.length;
    }
    getAgentData(uid) {
        return this.users.find(user => user.uid === uid);
    }
    //GETTERS
    get usersToAssignee() {
        return this.users.filter(user => !user.disabled);
    }
    get onlineUsersToAssignee() {
        const minOnline = this.getMinDateOnline();
        return this.users.filter(user => !user.disabled && this.isUserOnline(user, minOnline));
    }
    get offlineUsersToAssignee() {
        const minOnline = this.getMinDateOnline();
        return this.users.filter(user => !user.disabled && !this.isUserOnline(user, minOnline));
    }
    isUserOnline(user, minOnline = this.getMinDateOnline()) {
        if (user.status) {
            if ('offline' === user.status) {
                return false;
            }
            else if (user.statusTime > minOnline) {
                return true;
            }
        }
        else if (user.metadata && user.metadata.lastRefreshTime) {
            if (Date.parse(user.metadata.lastRefreshTime) > minOnline) {
                return true;
            }
        }
        return false;
    }
}
__decorate([
    computed
], AgentsStore.prototype, "usersMap", null);
__decorate([
    computed
], AgentsStore.prototype, "onlineCount", null);
__decorate([
    action
], AgentsStore.prototype, "searchUsers", void 0);
__decorate([
    action
], AgentsStore.prototype, "searchUsersThrottled", void 0);
__decorate([
    action
], AgentsStore.prototype, "clearSearchUsers", void 0);
__decorate([
    action
], AgentsStore.prototype, "getRoles", void 0);
__decorate([
    action
], AgentsStore.prototype, "onUsersStatusUpdated", void 0);
__decorate([
    action
], AgentsStore.prototype, "markFavorite", void 0);
__decorate([
    action
], AgentsStore.prototype, "unmarkFavorite", void 0);
export default AgentsStore;
