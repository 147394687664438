import classNames from 'classnames';
import React from 'react';
import { getDirection } from '../../../../helpers';
import QuickReplyButtonsBlock from '../RichMessage/Components/QuickReplyButtonsBlock';
import { createPortal } from 'react-dom';
import s from './ButtonsMessage.module.scss';
const ButtonsMessage = ({ text, buttons, afterFooterRef }) => {
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.buttonsWrapper },
            React.createElement("p", { dir: "auto", className: classNames(s.buttonsBody, { [s.rtl]: getDirection() === 'rtl' }) }, text),
            buttons.length > 0 &&
                afterFooterRef.current &&
                createPortal(React.createElement(QuickReplyButtonsBlock, { messageBlock: {
                        buttons: buttons.map((button) => ({ text: button.title || button.payload })),
                    } }), afterFooterRef.current))));
};
export default ButtonsMessage;
