import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Select } from 'antd';
import Icon, { UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { MySelect } from '../../../Base/Form';
import { useStore } from '../../../../store/rootStore';
import UploadComponent from './uploadComponent/UploadComponent';
import { ReactComponent as QuickRepliesGreenPlus } from '../../../../assets/icons/plus/greenPlus.svg';
import { MyTextArea } from '../../../Base/Form';
import './style.css';
const { Text } = Typography;
const { Option } = Select;
const AddOrEditReplyField = ({ reply, onChange, onCancel }) => {
    var _a;
    const [quickReply, setQuickReply] = useState(reply || { text: '' });
    const { t } = useTranslation();
    const { departmentsStore } = useStore();
    const handleChangeReply = (value) => {
        setQuickReply(Object.assign(Object.assign({}, quickReply), { text: value }));
    };
    const handleChangeDepartments = (value) => {
        setQuickReply(Object.assign(Object.assign({}, quickReply), { departments: value }));
    };
    const handleSave = () => {
        onChange(quickReply, !reply);
    };
    return (React.createElement("div", { className: 'AddReplyWrapper' },
        React.createElement("div", { className: 'addTitleReplies' },
            React.createElement(Icon, { component: QuickRepliesGreenPlus }),
            React.createElement(Text, { className: 'repliesAddTitleText' }, !reply ? React.createElement(Trans, { i18nKey: "editQuickReply" }) : React.createElement(Trans, { i18nKey: "createNewQuickReply" }))),
        React.createElement("div", { className: 'contentWrapper' },
            React.createElement("div", { className: 'textEditorBox' },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "quickReplyText" })),
                React.createElement("div", { className: 'repliesTextEditorBody' },
                    React.createElement(MyTextArea, { placeholderText: t('messageText'), text: quickReply === null || quickReply === void 0 ? void 0 : quickReply.text, setText: handleChangeReply, containerClassName: "repliesTextEditor", emojiPicker: true })),
                React.createElement("div", { className: 'attachWrapper' },
                    React.createElement(UploadComponent, null))),
            React.createElement("div", { className: "department-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "chooseDepartment" })),
                React.createElement(MySelect, { value: quickReply === null || quickReply === void 0 ? void 0 : quickReply.departments, onChange: handleChangeDepartments, dropdownStyle: { padding: 0 }, className: "department-dropdown", mode: "multiple", allowClear: true }, (_a = departmentsStore.departments) === null || _a === void 0 ? void 0 : _a.map((department, i) => (React.createElement(Option, { key: i, icon: React.createElement(UserOutlined, null), value: department._id },
                    React.createElement("div", { style: { backgroundColor: department.color ? department.color : 'lightgrey' }, className: "department-color" }),
                    department.name)))))),
        React.createElement("div", { className: 'addReplyBottom' },
            React.createElement(Button, { className: classNames('big', 'addReplyCancelButton'), onClick: onCancel },
                React.createElement(Text, { className: 'addReplyButtonsText' },
                    React.createElement(Trans, { i18nKey: "cancel" }))),
            React.createElement(Button, { className: classNames('big', 'addReplySaveButton'), type: "primary", onClick: handleSave },
                React.createElement("div", { className: 'replyButtonsTextSave' },
                    React.createElement(Trans, { i18nKey: "saveChanges" }))))));
};
export default AddOrEditReplyField;
