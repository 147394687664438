import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../InstagramMessage.module.scss';
const InstagramMessageLabel = ({ message, direction, chatTitle }) => {
    var _a;
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames(styles.messageType, {
            [styles.outgoing]: direction === MESSAGE_DIRECTION.OUT,
        }) },
        message.comment && (React.createElement(Trans, { i18nKey: "comment_on", values: { type: ((_a = message.comment) === null || _a === void 0 ? void 0 : _a.media.type) === 'FEED' ? t('post') : t('story') } })),
        message.isPostShare && (React.createElement(Trans, { i18nKey: message.isPostShare
                ? direction === MESSAGE_DIRECTION.OUT
                    ? 'shared_post_out'
                    : 'shared_post_in'
                : '', values: { chatTitle } })),
        message.reelVideoId && (React.createElement(Trans, { i18nKey: message.reelVideoId
                ? direction === MESSAGE_DIRECTION.OUT
                    ? 'shared_story_out'
                    : 'shared_story_in'
                : '', values: { chatTitle } })),
        message.storyReply && (React.createElement(Trans, { i18nKey: message.storyReply
                ? direction === MESSAGE_DIRECTION.OUT
                    ? 'replied_story_out'
                    : 'replied_story_in'
                : '', values: { chatTitle } })),
        message.storyMention && (React.createElement(Trans, { i18nKey: message.storyMention
                ? direction === MESSAGE_DIRECTION.OUT
                    ? 'mentioned_story_out'
                    : 'mentioned_story_in'
                : '', values: { chatTitle } }))));
};
export default InstagramMessageLabel;
