import React from 'react';
import classNames from 'classnames';
import { MyInput } from '../../Base/Form';
import s from './DefaultTemplateReplyBar.module.scss';
const TemplateInput = ({ disabled, textDirection, value, onChange, onKeyDown, forwardRef, placeholder, }) => {
    return (React.createElement("div", { className: s.textAreaWrapper },
        React.createElement(MyInput.TextArea, { disabled: disabled, dir: textDirection, className: classNames(s.input, {
                [s.rtl]: textDirection === 'rtl',
            }), placeholder: placeholder, value: value, size: "large", autoSize: { maxRows: 1 }, onChange: (value, selectionStart, selectionEnd) => {
                onChange(value, selectionStart, selectionEnd);
            }, onBlur: (e) => {
                onChange(e.target.value, e.target.selectionStart, e.target.selectionEnd);
            }, onKeyDown: onKeyDown, forwardRef: forwardRef, maxLength: 1024 })));
};
export default TemplateInput;
