import React from 'react';
import classNames from 'classnames';
import { Tag, Typography } from 'antd';
import MessagePreview from '../../Chat/QuickReplies/TemplateGenerator/AddOrEditTemplate/MessagePreview';
import { WhatsApp } from '@whatsper/texterchat-common';
import s from './Template.module.scss';
const { Text } = Typography;
const categoryColorMap = {
    [WhatsApp.TemplateCategory.UTILITY]: 'blue',
    [WhatsApp.TemplateCategory.MARKETING]: 'green',
    [WhatsApp.TemplateCategory.AUTHENTICATION]: 'orange',
};
const Template = ({ template, highlighted, onClicked, isInbox, isRTL }) => {
    var _a, _b;
    return (React.createElement("div", { className: classNames(s.template, {
            [s.greenBackground]: highlighted,
            [s.hoverable]: !highlighted,
            [s.inbox]: isInbox,
        }), onClick: onClicked },
        React.createElement("div", { className: classNames(s.title, {
                [s.rtl]: isRTL,
            }) },
            template.title,
            React.createElement(Text, { className: s.subtitle }, template.name),
            React.createElement(Tag, { color: categoryColorMap[template.category], className: s.categoryTag }, template.category)),
        React.createElement("div", { className: s.content },
            React.createElement(MessagePreview, { header: Object.assign(Object.assign({}, template.header), { type: (_a = template.header) === null || _a === void 0 ? void 0 : _a.type, text: ((_b = template.header) === null || _b === void 0 ? void 0 : _b.text) || '' }), body: {
                    text: template.text,
                }, footer: template.footer, buttons: template.buttons, canCollapse: true }))));
};
export default Template;
