var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from "mobx";
import FileSaver from "file-saver";
import moment from "moment";
import _, { throttle } from "lodash";
import { EventName, MESSAGE_STATUS, TaskName, ScheduleType, isRegularMessage, isTextMessage, } from "@whatsper/texterchat-common";
import { API_PATH } from "../../constants";
import { getAxios } from "../../backend";
import { message } from "antd";
export var BulkReportContactDataOptions;
(function (BulkReportContactDataOptions) {
    BulkReportContactDataOptions["SENT"] = "sent";
    BulkReportContactDataOptions["VIEWED"] = "viewed";
    BulkReportContactDataOptions["UNSUBSCRIBED"] = "unsubscribed";
    BulkReportContactDataOptions["REACTIONS"] = "reactions";
    BulkReportContactDataOptions["DELIVERED"] = "delivered";
})(BulkReportContactDataOptions || (BulkReportContactDataOptions = {}));
const loadBulkReportsThrottle = 1000;
const bulkReportsItems = 30;
export default class BulkReportsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_bulkReportsResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_singleBulkReportResult", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_scheduledReports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bulkReportsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "singleBulkReportLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "scheduledReportsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "totalCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "_prepareContactsDataForDownload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data, type) => {
                let csv = "";
                const utfFix = "\uFEFF";
                const fileType = "text/csv;charset=utf-8;";
                if (type === "all") {
                    const [sent, viewed, unsubscribed, reactions, delivered] = data;
                    let header = ["Phone", "Sent", "Received", "Viewed (vv)", "Unsubscribed"];
                    if (Object.keys(reactions).length > 0) {
                        header = [...header, ...Object.keys(reactions)];
                    }
                    const body = sent
                        .map(({ clientPhoneE164 }) => {
                        const row = [clientPhoneE164, "Y"];
                        delivered.find((d) => d.clientPhoneE164 === clientPhoneE164) ? row.push("Y") : row.push("");
                        viewed.find((v) => v.clientPhoneE164 === clientPhoneE164) ? row.push("Y") : row.push("");
                        unsubscribed.find((u) => u.clientPhoneE164 === clientPhoneE164) ? row.push("Y") : row.push("");
                        if (Object.keys(reactions).length > 0) {
                            Object.keys(reactions).forEach((r) => {
                                reactions[r].find((re) => re.clientPhoneE164 === clientPhoneE164) ? row.push("Y") : row.push("");
                            });
                        }
                        return row.join(",");
                    })
                        .join("\n");
                    csv = `${utfFix}${header}\n${body}`;
                }
                else if (!Array.isArray(data)) {
                    if (type === BulkReportContactDataOptions.REACTIONS) {
                        const header = ["Name", "Phone", "Reaction"];
                        const body = Object.entries(data)
                            .map(([reaction, contact]) => contact.map(({ title, clientPhoneE164 }) => [title, clientPhoneE164, reaction].join(",")).join("\n"))
                            .join("\n");
                        csv = `${utfFix}${header}\n${body}`;
                    }
                }
                else if (data.length > 0) {
                    const header = ["Name", "Phone"];
                    const body = data.map((row) => Object.values(row).splice(-2).reverse().join(",")).join("\n");
                    csv = `${utfFix}${header}\n${body}`;
                }
                const templateName = this.singleReport ? this.singleReport.templateName : "";
                let date = this.singleReport ? this.singleReport.date : "";
                date = moment(date).format("D MMM YYYY");
                const fileName = `${templateName}_${date}_${type}.csv`;
                return { fileName, csv, fileType };
            }
        });
        Object.defineProperty(this, "fetchBulkReports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query = {}, more = false) => __awaiter(this, void 0, void 0, function* () {
                try {
                    runInAction(() => (this.bulkReportsLoading = true));
                    this._loadBulkReportsData(query, more);
                }
                catch (error) {
                    console.error("Error on fetching bulk reports", error);
                    runInAction(() => (this.bulkReportsLoading = false));
                }
            })
        });
        Object.defineProperty(this, "fetchScheduledReports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const query = {
                    task: TaskName.SendBulkTemplateMessagesTask,
                    type: ScheduleType.database,
                };
                try {
                    runInAction(() => (this.scheduledReportsLoading = true));
                    return getAxios()
                        .then((axios) => {
                        return axios.get(API_PATH.SCHEDULE, {
                            params: Object.assign({}, query),
                        });
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this._scheduledReports = observable(data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on fetching scheduled reports', error);
                    runInAction(() => (this.scheduledReportsLoading = false));
                }
            })
        });
        Object.defineProperty(this, "deleteDatabaseTasksByBulkId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bulkId) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const tasksToDelete = (_a = this._scheduledReports) === null || _a === void 0 ? void 0 : _a.filter((r) => { var _a; return ((_a = r.params) === null || _a === void 0 ? void 0 : _a.bulkId) === bulkId; });
                const ids = tasksToDelete === null || tasksToDelete === void 0 ? void 0 : tasksToDelete.map((r) => r._id);
                if (!ids) {
                    console.error(`No ids found for scheduled tasks to delete for bulkId: ${bulkId}`);
                    return;
                }
                try {
                    runInAction(() => (this.scheduledReportsLoading = true));
                    yield this._deleteScheduledReport(ids);
                    runInAction(() => {
                        if (this._scheduledReports) {
                            this._scheduledReports = this._scheduledReports.filter((r) => r.params.bulkId !== bulkId);
                        }
                    });
                }
                catch (error) {
                    console.error("Error on deleting scheduled tasks", error);
                }
                finally {
                    runInAction(() => (this.scheduledReportsLoading = false));
                }
            })
        });
        Object.defineProperty(this, "_deleteScheduledReport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (databaseTaskIds) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const maxRetries = 3;
                    const retryDelay = 30000; // 30 seconds
                    const retryDelays = Array(maxRetries).fill(retryDelay);
                    const deleteWithRetry = (id, retryCount = 0) => __awaiter(this, void 0, void 0, function* () {
                        try {
                            yield this._deleteSingleReport(id);
                        }
                        catch (error) {
                            if (retryCount < maxRetries) {
                                const delay = retryDelays[retryCount];
                                yield new Promise((resolve) => setTimeout(resolve, delay));
                                return deleteWithRetry(id, retryCount + 1);
                            }
                            throw error;
                        }
                    });
                    yield Promise.all(databaseTaskIds.map(deleteWithRetry));
                    runInAction(() => (this.scheduledReportsLoading = false));
                }
                catch (error) {
                    console.error("Error on fetching scheduled reports", error);
                    runInAction(() => (this.scheduledReportsLoading = false));
                }
            })
        });
        Object.defineProperty(this, "_deleteSingleReport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (databaseId) => __awaiter(this, void 0, void 0, function* () {
                return getAxios().then((axios) => {
                    return axios.delete(`${API_PATH.SCHEDULE}/database/${databaseId}`);
                });
            })
        });
        Object.defineProperty(this, "_loadBulkReportsData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle((query, more) => __awaiter(this, void 0, void 0, function* () {
                if (more) {
                    if (this.totalCount !== null && !this.searchHasMore) {
                        console.warn("Skipped loading more because all bulk reports are loaded already");
                        return;
                    }
                    query.skip = `${this.reportsList.length}`;
                }
                query.limit = `${bulkReportsItems}`;
                return getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.BULK_REPORTS, {
                        params: Object.assign({}, query),
                    });
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        var _a;
                        if (more) {
                            (_a = this._bulkReportsResults) === null || _a === void 0 ? void 0 : _a.items.push(...data.items);
                        }
                        else {
                            this._bulkReportsResults = observable(data);
                        }
                        this.totalCount = data.total;
                        this.bulkReportsLoading = false;
                    });
                });
            }), loadBulkReportsThrottle, { trailing: true })
        });
        Object.defineProperty(this, "fetchSingleBulkReport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => __awaiter(this, void 0, void 0, function* () {
                try {
                    runInAction(() => (this.singleBulkReportLoading = true));
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.BULK_REPORTS}/${id}`);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this._singleBulkReportResult = observable(data);
                            this.singleBulkReportLoading = false;
                        });
                    });
                }
                catch (error) {
                    console.error("Error fetching bulk report", error);
                    message.error(`Error showing bulk report. Please report this issue to support. ID: ${id}`, 10);
                    runInAction(() => {
                        this.singleBulkReportLoading = false;
                        this._singleBulkReportResult = null;
                    });
                }
            })
        });
        Object.defineProperty(this, "updateSingleBulkReport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, body) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.patch(`${API_PATH.BULK_REPORTS}/${id}`, body);
                    })
                        .then((response) => {
                        runInAction(() => {
                            var _a;
                            if (response.status === 204) {
                                if (this._singleBulkReportResult) {
                                    this._singleBulkReportResult = observable(Object.assign(Object.assign({}, this._singleBulkReportResult), body));
                                }
                                if (this._bulkReportsResults) {
                                    this._bulkReportsResults.items = (_a = this._bulkReportsResults) === null || _a === void 0 ? void 0 : _a.items.map((report) => report._id === id ? Object.assign(Object.assign({}, report), body) : report);
                                }
                            }
                        });
                    });
                }
                catch (error) {
                    console.error("Error on patching single bulk report", error);
                }
            })
        });
        Object.defineProperty(this, "downloadListOfContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, type) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.BULK_REPORTS}/${id}/${type}`);
                    })
                        .then((response) => {
                        const { fileName, csv, fileType } = this._prepareContactsDataForDownload(response.data, type);
                        const csvFile = new Blob([csv], { type: fileType });
                        FileSaver.saveAs(csvFile, fileName);
                    });
                }
                catch (error) {
                    console.error("Error on downloading list of contacts sent", error);
                }
            })
        });
        Object.defineProperty(this, "downloadAllData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => __awaiter(this, void 0, void 0, function* () {
                try {
                    return getAxios()
                        .then((axios) => {
                        const requests = Object.values(BulkReportContactDataOptions).map((type) => axios.get(`${API_PATH.BULK_REPORTS}/${id}/${type}`));
                        return Promise.all(requests);
                    })
                        .then((responses) => {
                        const data = responses.map((response) => response.data);
                        const { fileName, csv, fileType } = this._prepareContactsDataForDownload(data, "all");
                        const csvFile = new Blob([csv], { type: fileType });
                        FileSaver.saveAs(csvFile, fileName);
                    });
                }
                catch (error) {
                    console.error("Error on downloading list of contacts sent", error);
                }
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events.addListener(EventName.message_created, (data) => this.onMessageCreatedEvent(data));
        this.rootStore.events.addListener(EventName.message_updated, (data) => this.onMessageUpdatedEvent(data));
    }
    get reportsList() {
        return this._bulkReportsResults
            ? this._bulkReportsResults.items
            : observable.array();
    }
    get scheduledReportsList() {
        if (!this._scheduledReports) {
            return observable.array();
        }
        const groupedReportsByBulkId = _(this._scheduledReports)
            .groupBy('params.bulkId')
            .map((objs) => objs[0])
            .value();
        return groupedReportsByBulkId
            .map((item) => ({
            templateTitle: item.params.templateId,
            templateName: '',
            _id: item.params.bulkId,
            date: new Date(item.params.scheduled * 1000).toISOString(),
        }))
            .filter(({ date }) => moment(new Date(date)).isAfter(moment()));
    }
    get hasScheduledReports() {
        return this.scheduledReportsList.length > 0;
    }
    get singleReport() {
        return this._singleBulkReportResult ? this._singleBulkReportResult : null;
    }
    get searchHasMore() {
        return this._bulkReportsResults && this._bulkReportsResults.total > this._bulkReportsResults.items.length;
    }
    onMessageCreatedEvent(message) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (isRegularMessage(message) && ((_a = message.metadata) === null || _a === void 0 ? void 0 : _a.bulkSendId)) {
            const teaserExists = (_b = this._bulkReportsResults) === null || _b === void 0 ? void 0 : _b.items.some((report) => { var _a; return report._id === ((_a = message.metadata) === null || _a === void 0 ? void 0 : _a.bulkSendId); });
            if (!teaserExists) {
                this.fetchBulkReports();
            }
            if (message.metadata.bulkSendId === ((_c = this._singleBulkReportResult) === null || _c === void 0 ? void 0 : _c._id) &&
                message.status === MESSAGE_STATUS.MESSAGE_ACCEPTED) {
                this._singleBulkReportResult = Object.assign(Object.assign({}, this._singleBulkReportResult), { sent: this._singleBulkReportResult.sent + 1 });
            }
        }
        if (isRegularMessage(message) && ((_e = (_d = message.context) === null || _d === void 0 ? void 0 : _d.metadata) === null || _e === void 0 ? void 0 : _e.bulkSendId)) {
            if (isTextMessage(message) && ((_f = message.context) === null || _f === void 0 ? void 0 : _f.metadata.bulkSendId) === ((_g = this._singleBulkReportResult) === null || _g === void 0 ? void 0 : _g._id)) {
                const { text } = message;
                const { reactions } = this._singleBulkReportResult;
                if (text && reactions && reactions.some((r) => r.title === text)) {
                    this._singleBulkReportResult = Object.assign(Object.assign({}, this._singleBulkReportResult), { reactions: reactions.map((r) => (Object.assign(Object.assign({}, r), { count: r.title === text ? r.count + 1 : r.count }))) });
                }
            }
        }
    }
    onMessageUpdatedEvent(message) {
        var _a, _b, _c;
        if (isRegularMessage(message)
            && ((_a = message.metadata) === null || _a === void 0 ? void 0 : _a.bulkSendId)) {
            if (((_b = message.metadata) === null || _b === void 0 ? void 0 : _b.bulkSendId) === ((_c = this._singleBulkReportResult) === null || _c === void 0 ? void 0 : _c._id)) {
                if (message.status === MESSAGE_STATUS.MESSAGE_SEEN) {
                    this._singleBulkReportResult = Object.assign(Object.assign({}, this._singleBulkReportResult), { messageStatus: Object.assign(Object.assign({}, this._singleBulkReportResult.messageStatus), { [message.status]: this._singleBulkReportResult.messageStatus[message.status] === undefined
                                ? 1
                                : this._singleBulkReportResult.messageStatus[message.status] || 0 + 1 }) });
                }
            }
        }
    }
}
__decorate([
    observable
], BulkReportsStore.prototype, "_bulkReportsResults", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "_singleBulkReportResult", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "_scheduledReports", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "bulkReportsLoading", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "singleBulkReportLoading", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "scheduledReportsLoading", void 0);
__decorate([
    observable
], BulkReportsStore.prototype, "totalCount", void 0);
__decorate([
    computed
], BulkReportsStore.prototype, "reportsList", null);
__decorate([
    computed
], BulkReportsStore.prototype, "scheduledReportsList", null);
__decorate([
    computed
], BulkReportsStore.prototype, "hasScheduledReports", null);
__decorate([
    computed
], BulkReportsStore.prototype, "singleReport", null);
__decorate([
    computed
], BulkReportsStore.prototype, "searchHasMore", null);
__decorate([
    action
], BulkReportsStore.prototype, "onMessageCreatedEvent", null);
__decorate([
    action
], BulkReportsStore.prototype, "onMessageUpdatedEvent", null);
__decorate([
    action
], BulkReportsStore.prototype, "fetchBulkReports", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "fetchScheduledReports", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "deleteDatabaseTasksByBulkId", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "fetchSingleBulkReport", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "updateSingleBulkReport", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "downloadListOfContacts", void 0);
__decorate([
    action
], BulkReportsStore.prototype, "downloadAllData", void 0);
