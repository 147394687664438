import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ActiveIcon from '../../Chat/ActiveIcon';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/dashboard/Dashboard.svg';
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download/file-download.svg";
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { getDirection } from '../../../helpers';
import s from '../DashboardDrawer.module.scss';
const Header = ({ toggleDashboardDrawer, latestTimestamp, setSLAModalVisible, setDownloadDataModalVisible, isLive }) => {
    const { t } = useTranslation();
    const workingHours = moment(latestTimestamp === null || latestTimestamp === void 0 ? void 0 : latestTimestamp.starts).format('HH:mm') + ' - ' + moment(latestTimestamp === null || latestTimestamp === void 0 ? void 0 : latestTimestamp.ends).format('HH:mm');
    const menu = () => {
        const items = [
            {
                key: 'setSLAHours',
                label: t('setSLAHours'),
                onClick: () => setSLAModalVisible(true),
                icon: React.createElement(Dashboard, null),
            },
            {
                key: 'downloadData',
                label: t('downloadData'),
                onClick: () => setDownloadDataModalVisible(true),
                icon: React.createElement(DownloadIcon, null),
            }
        ];
        return Object.assign({ items }, getDirection() === 'rtl' && { style: { direction: 'rtl' } });
    };
    return (React.createElement("div", { className: s['dashboard__header'] },
        React.createElement("div", { className: s['dashboard__header--leftContainer'] },
            React.createElement("div", { className: s['dashboard__header--titleContainer'] },
                React.createElement("div", { className: s['dashboard__header--title'] },
                    isLive && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: s['title__dot'] }),
                        React.createElement("div", { className: s['title__live'] }, "LIVE"))),
                    React.createElement("div", null, "Dashboard"),
                    React.createElement("div", null,
                        "(",
                        workingHours,
                        ")")),
                React.createElement("div", { className: s['title__currentDate'] }, moment().format('h:mm A, LL')))),
        React.createElement("div", { className: s['dashboard__header--actionWrapper'] },
            React.createElement(Dropdown, { trigger: ['click'], placement: getDirection() === 'rtl' ? 'bottomLeft' : 'bottomRight', overlayClassName: s["dashboard__header--dropdown"], menu: menu() },
                React.createElement(MoreOutlined, { className: s['dashboard__header--moreIcon'] })),
            React.createElement(ActiveIcon, { component: QuickRepliesCross, action: () => toggleDashboardDrawer(false) }))));
};
export default observer(Header);
