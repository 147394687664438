import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Input } from 'antd';
import { omit } from 'lodash';
import { SmileOutlined } from '@ant-design/icons';
import pickerData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useTranslation } from 'react-i18next';
import { getDirection } from '../../../../helpers';
import styles from './MyTextArea.module.scss';
import classNames from 'classnames';
const { TextArea } = Input;
const MyTextArea = ({ placeholderText, text, setText, containerClassName, align, forwardRef, textareaProps, 
/**
 * If true, emoji picker will be shown
 */
emojiPicker = false, }) => {
    const { t } = useTranslation();
    const [openEmoji, setOpenEmoji] = useState(false);
    const inputRef = useRef(null);
    const emojiRef = useRef(null);
    const smileRef = useRef(null);
    const textAreaRef = forwardRef || inputRef;
    useEffect(() => {
        if (emojiPicker) {
            document.addEventListener('click', closeEmojiFromOutside);
        }
        return () => {
            document.removeEventListener('click', closeEmojiFromOutside);
        };
    }, [emojiPicker]);
    const closeEmojiFromOutside = (event) => {
        var _a;
        if (openEmoji &&
            emojiRef.current &&
            !emojiRef.current.contains(event.target) &&
            !((_a = smileRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            setOpenEmoji(false);
        }
    };
    const handleAddEmoji = (emoji, start, end) => {
        const stringEnd = text.length;
        const newText = `${text.slice(0, start)}${emoji}${text.slice(end, stringEnd)}`;
        setText(newText);
    };
    const setSelectionRange = (start, end) => {
        var _a;
        (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea.textArea.setSelectionRange(start, end);
    };
    const onEmojiSelect = (emojiObject) => {
        var _a, _b, _c;
        const selectionStart = ((_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea.textArea.selectionStart) || 0;
        const selectionEnd = ((_b = textAreaRef.current) === null || _b === void 0 ? void 0 : _b.resizableTextArea.textArea.selectionEnd) || 0;
        handleAddEmoji(emojiObject.native, selectionStart, selectionEnd);
        (_c = textAreaRef.current) === null || _c === void 0 ? void 0 : _c.focus();
        setSelectionRange(selectionStart + 2, selectionEnd + 2);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(styles.textbox, containerClassName, {
                [styles.disabled]: textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.disabled,
            }), onClick: () => {
                var _a;
                (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } },
            React.createElement(TextArea, Object.assign({ dir: getDirection(), ref: textAreaRef, className: classNames(styles.textarea, textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.className), placeholder: t(`${placeholderText}`), onChange: (e) => {
                    setSelectionRange(e.target.selectionStart, e.target.selectionEnd);
                    setText(e.target.value);
                }, onClick: (e) => {
                    const target = e.target;
                    setSelectionRange(target.selectionStart, target.selectionEnd);
                    if (textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.onClick) {
                        textareaProps.onClick(e);
                    }
                }, onBlur: (e) => {
                    if (textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.onBlur) {
                        textareaProps.onBlur(e);
                    }
                }, onFocus: (e) => {
                    setSelectionRange(e.target.selectionStart, e.target.selectionEnd);
                    if (textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.onFocus) {
                        textareaProps.onFocus(e);
                    }
                }, autoSize: { minRows: 5 }, value: text, bordered: false }, omit(textareaProps, 'onFocus', 'onBlur', 'onChange', 'onClick', 'className'))),
            emojiPicker && (React.createElement(SmileOutlined, { ref: smileRef, className: styles.emojiPickerTrigger, onClick: () => {
                    if (!(textareaProps === null || textareaProps === void 0 ? void 0 : textareaProps.disabled)) {
                        setOpenEmoji(!openEmoji);
                    }
                } }))),
        openEmoji && (React.createElement("div", { ref: emojiRef, className: classnames(styles.emojiPicker, {
                [styles.top]: align === 'top',
                [styles.bottom]: align === 'bottom',
            }) },
            React.createElement(Picker, { data: pickerData, className: styles.emojiPickerInner, onClickOutside: closeEmojiFromOutside, onEmojiSelect: onEmojiSelect, theme: "light" })))));
};
export default MyTextArea;
