import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import styles from './LoadingIcon.module.scss';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
const LoadingIcon = ({ additionalStyles, style, showInfoMessageForLongLoadingTime }) => {
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    useEffect(() => {
        if (showInfoMessageForLongLoadingTime) {
            setTimeout(() => {
                setShowInfoMessage(true);
            }, 5000);
        }
    }, [showInfoMessageForLongLoadingTime]);
    return (React.createElement("div", { className: styles.container },
        React.createElement(LoadingOutlined, { style: style, className: classnames(styles.spinner, additionalStyles) }),
        React.createElement("div", { className: classNames(styles.infoMessage, {
                [styles.visible]: showInfoMessage,
            }) },
            React.createElement(Trans, { i18nKey: "longLoadingInfoMessageDotted" }))));
};
export default LoadingIcon;
