import { useEffect, useState } from 'react';
import { checkForInvisibleCharacters } from '../../../../helpers/checkForInvisibleCharacters';
const shouldShowWarningIfContainsInvisibleCharacters = (value, showWarning) => {
    return !!(showWarning && value && typeof value === 'string' && checkForInvisibleCharacters(value).length > 0);
};
/**
 * A hook that first checks if input should look for invisible characters and then checks if the input contains invisible characters.
 *
 * @param value - The value of the input
 * @param warnIfContainsInvisibleCharacters - If set to true, a warning icon will be displayed next to the input if it contains invisible characters.
 * @returns
 */
const useShouldShowWarningIfContainsInvisibleCharacters = (value, warnIfContainsInvisibleCharacters) => {
    const [showWarningIfContainsInvisibleCharacters, setShowWarningIfContainsInvisibleCharacters] = useState(false);
    useEffect(() => {
        if (warnIfContainsInvisibleCharacters && value) {
            setShowWarningIfContainsInvisibleCharacters(shouldShowWarningIfContainsInvisibleCharacters(value, warnIfContainsInvisibleCharacters));
        }
    }, [value, warnIfContainsInvisibleCharacters]);
    return showWarningIfContainsInvisibleCharacters;
};
export default useShouldShowWarningIfContainsInvisibleCharacters;
