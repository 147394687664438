// Function to identify all characters and their code points
function findAllCharacters(input) {
    const results = [];
    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        const codePoint = char.codePointAt(0);
        if (!codePoint)
            continue; // Skip invalid characters
        results.push({ char, codePoint, index: i });
    }
    return results;
}
// Function to filter out printable characters
function findInvisibleCharacters(input) {
    const allChars = findAllCharacters(input);
    return allChars.filter(({ codePoint }) => {
        // Exclude printable ASCII characters (32–126) and newlines
        return !(codePoint >= 32 && codePoint <= 126) && codePoint !== 10 && codePoint !== 13;
    });
}
// Function to map invisible characters to a visible representation
function mapInvisibleCharacters(input) {
    const invisibleCharDescriptions = {
        0x200b: '[ZWSP]',
        0x200c: '[ZWNJ]',
        0x200d: '[ZWJ]',
        0x2060: '[WJ]',
        0xfeff: '[BOM]',
        0x00a0: '[NBSP]',
        0x2028: '[LS]',
        0x2029: '[PS]', // Paragraph separator
    };
    const invisibleChars = findInvisibleCharacters(input);
    return invisibleChars.map(({ char, codePoint, index }) => ({
        char,
        codePoint,
        index,
        description: invisibleCharDescriptions[codePoint] || `[U+${codePoint.toString(16).toUpperCase()}]`,
    }));
}
/**
 * Checks if input contains invisible characters, and returns them. In case of no invisible characters, an empty array is returned.
 *
 * @param input - The input string to check
 * @returns An array of invisible characters with their code points and descriptions
 */
export const checkForInvisibleCharacters = (input) => {
    const invisibleChars = mapInvisibleCharacters(input);
    return invisibleChars;
};
