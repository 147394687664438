import React from 'react';
import classNames from 'classnames';
import SafeHTMLMarkdown from '../../../Base/Utils/SafeHTMLMarkdown';
import MessageMarkdown from '../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import styles from './TextMessage.module.css';
const TextMessage = ({ text, deleted, outgoing, renderMessageAsHTML }) => {
    const mergedClassnames = classNames(styles.messageText, {
        [styles.deletedText]: deleted,
        [styles.whiteText]: outgoing,
    });
    if (renderMessageAsHTML) {
        return (React.createElement(SafeHTMLMarkdown, { message: text, attributes: {
                dir: 'auto',
                className: mergedClassnames,
            } }));
    }
    return (React.createElement("p", { dir: "auto", className: mergedClassnames },
        React.createElement(MessageMarkdown, { text: text })));
};
export default TextMessage;
