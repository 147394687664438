var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import classnames from 'classnames';
import FileSaver from "file-saver";
import { TEMPLATE_HEADER_TYPE } from '@whatsper/texterchat-common';
import defaultFile from '../../../../../../assets/icons/file/defaultFile.png';
import { TEMPLATE_PARTS } from '../../../../../../constants';
import { getDownloadUrlBasedOnPlatform } from '../../../../../../helpers';
import MessageMarkdown from '../../../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import './MainBlock.scss';
class MainBlock extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "getText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { parameters, messageBlock } = this.props;
                let text = messageBlock.text;
                if (!parameters)
                    return text;
                let index = 1;
                parameters.forEach((param) => {
                    text = text.replace('{{' + index + '}}', param);
                    index++;
                });
                return text;
            }
        });
        Object.defineProperty(this, "getRtlDirection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { messageBlock } = this.props;
                const text = messageBlock.text;
                if (/[\u0590-\u05FF]/.test(text) || /[\u0600-\u06FF]/.test(text)) {
                    return true;
                }
                else
                    return false;
            }
        });
        Object.defineProperty(this, "handleDownload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const url = yield getDownloadUrlBasedOnPlatform(this.props.messageBlock.url);
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        event: 'openURL',
                        url,
                    }));
                }
                else {
                    FileSaver.saveAs(url, 'document');
                }
            })
        });
    }
    render() {
        var _a, _b;
        const { messageBlock, type: partType } = this.props;
        /*
         * Sometimes type or format comes in lowercase like 'image' instead of 'IMAGE',
         * not sure why or since when, temporary client fix until resolved properly
        */
        const format = (_a = messageBlock.format) === null || _a === void 0 ? void 0 : _a.toUpperCase();
        const type = (_b = messageBlock.type) === null || _b === void 0 ? void 0 : _b.toUpperCase();
        return (React.createElement("div", { className: "rich-message-main-block" },
            format === TEMPLATE_HEADER_TYPE.TEXT && partType === TEMPLATE_PARTS.HEADER ? (React.createElement("div", { className: classnames('rich-message-header', { 'rich-message-rtl': this.getRtlDirection() }) }, messageBlock.text)) : null,
            (format === TEMPLATE_HEADER_TYPE.IMAGE ||
                type === TEMPLATE_HEADER_TYPE.IMAGE) &&
                partType === TEMPLATE_PARTS.HEADER &&
                messageBlock.url ? (React.createElement("img", { src: messageBlock.url, className: "rich-message-header-image" })) : null,
            (format === TEMPLATE_HEADER_TYPE.VIDEO ||
                type === TEMPLATE_HEADER_TYPE.VIDEO) &&
                partType === TEMPLATE_PARTS.HEADER &&
                messageBlock.url ? (React.createElement("video", { src: messageBlock.url, className: "rich-message-header-image", controls: true })) : null,
            (format === TEMPLATE_HEADER_TYPE.DOCUMENT ||
                type === TEMPLATE_HEADER_TYPE.DOCUMENT) &&
                partType === TEMPLATE_PARTS.HEADER ? (React.createElement("div", { className: "fileDownloadPreview", onClick: this.handleDownload },
                React.createElement("img", { src: defaultFile, className: "rich-message-header-image" }),
                React.createElement("div", null, "Download file"))) : null,
            partType === TEMPLATE_PARTS.BODY ? (React.createElement("div", { className: classnames('rich-message-body', { 'rich-message-rtl': this.getRtlDirection() }) },
                React.createElement(MessageMarkdown, { text: this.getText() }))) : null,
            partType === TEMPLATE_PARTS.FOOTER ? (React.createElement("div", { className: classnames('rich-message-footer', { 'rich-message-rtl': this.getRtlDirection() }) }, messageBlock.text)) : null));
    }
}
export default MainBlock;
