import React from "react";
import { Pie } from "@ant-design/plots";
import { useTranslation } from "react-i18next";
export const randomColors = ["#EE4ACA", "#546BD6", "#00DC83"]; // random colors?
const ReactionsChart = ({ reactions }) => {
    const { t } = useTranslation();
    const total = reactions.reduce((sum, item) => sum + item.count, 0);
    const config = Object.assign({ height: 105, width: 105, appendPadding: 0, data: reactions, angleField: "count", colorField: "title", color: randomColors, radius: 1, innerRadius: 0.7, interactions: [
            {
                type: "element-active",
            },
        ], label: undefined, legend: false, animation: false, statistic: undefined }, total === 0 && {
        data: [{ title: t('noData'), count: 0 }],
        color: '#f0f0f0',
    });
    return React.createElement(Pie, Object.assign({}, config));
};
export default ReactionsChart;
