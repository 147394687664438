import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Typography, Tooltip, Divider } from 'antd';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { Trans } from 'react-i18next';
import CommonButton from '../../../common/CommonButton';
import { WhatsApp } from '@whatsper/texterchat-common';
import { withStore } from '../../../../../store/rootStore';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/pencil/penIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/deleteIcon/deleteIcon.svg';
import { ReactComponent as threeDots } from '../../../../../assets/preloaders/threeDots.svg';
import { ReactComponent as greenTick } from '../../../../../assets/messageStatuses/greenTick.svg';
import { ReactComponent as greenPlus } from '../../../../../assets/icons/plus/greenPlus.svg';
import { ReactComponent as redCross } from '../../../../../assets/icons/chatStatuses/redCross.svg';
import hebrewCheck from '../../../../Base/Utils/HebrewCheck';
import s from './SingleTemplate.module.scss';
import i18next from 'i18next';
const { Text } = Typography;
const SingleTemplate = ({ templateText, setTemplateEdited, setTemplateDeleting, template, store, isRTL, canManageTemplates, }) => {
    const { submitWhatsAppLoading, handleSubmitToWhatsApp, } = store.templateMessagesStore;
    const { status } = template.obj.localizations[0];
    const isNew = !status;
    const isDeleted = status === WhatsApp.TemplateLocalizationStatus.DELETED;
    const isRejected = status === WhatsApp.TemplateLocalizationStatus.REJECTED;
    const isPending = status === WhatsApp.TemplateLocalizationStatus.PENDING;
    const isApproved = status === WhatsApp.TemplateLocalizationStatus.APPROVED;
    return (React.createElement("div", { id: `#${template.name}`, className: classnames(s.templateMessageField, {
            [s.canManageTemplates]: canManageTemplates,
            [s.rtl]: isRTL,
        }), onClick: () => {
            if (!isRejected && canManageTemplates)
                setTemplateEdited(template);
        } },
        React.createElement("div", { className: classnames(s.templateNameContainer, {
                [s.rtl]: isRTL,
            }) },
            template.title && (React.createElement(Text, { className: classnames(s.templateTitleText, {
                    [s.templateTextRtl]: hebrewCheck(template.title),
                }) }, template.title)),
            React.createElement(Text, { className: classnames(s.templateIdText, { [s.templateTextRtl]: hebrewCheck(template.title) }) },
                React.createElement("span", null, template.name),
                React.createElement(Divider, { type: "vertical" }),
                React.createElement("span", null, moment(template.createdAt).format('l LT'))),
            React.createElement(Text, { className: classnames(s.templateText, { [s.templateTextRtl]: hebrewCheck(templateText) }) }, templateText)),
        React.createElement("div", { className: s.singleTemplateRightBlock },
            canManageTemplates && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: s.singleTemplateButtons },
                    React.createElement(Icon, { className: classnames(s.templateButtonEdit, {
                            [s.rtl]: isRTL,
                        }), component: EditIcon, onClick: () => setTemplateEdited(template) }),
                    React.createElement(Icon, { className: classnames(s.templateButtonDelete, { [s.isDeleted]: isDeleted }), component: DeleteIcon, disabled: isDeleted, onClick: (e) => {
                            e.stopPropagation();
                            if (isDeleted)
                                return;
                            setTemplateDeleting(template);
                        } })),
                !status && (React.createElement("div", { className: s.singleTemplateButtons },
                    React.createElement(CommonButton, { submit: true, additionalStyles: s.customSendToWhatsappButton, loading: submitWhatsAppLoading, disabled: submitWhatsAppLoading, action: handleSubmitToWhatsApp },
                        React.createElement(Trans, { i18nKey: "sendToTyntec" })))))),
            React.createElement(Tooltip, Object.assign({ placement: "topLeft", title: template.obj.localizations[0].rejectionReason }, (!isRejected && { open: false })),
                React.createElement("div", { className: s.templateStatus },
                    isNew && React.createElement(Icon, { component: greenPlus }),
                    isApproved && React.createElement(Icon, { component: greenTick }),
                    isPending && React.createElement(Icon, { component: threeDots }),
                    isRejected && React.createElement(Icon, { component: redCross }),
                    React.createElement("p", { className: classnames(s.templateStatusText, {
                            [s.templateStatusTextNew]: isNew,
                            [s.templateStatusTextPending]: isPending,
                            [s.templateStatusTextApproved]: isApproved,
                            [s.templateStatusTextDeclined]: isRejected,
                        }) }, i18next.t(status) || i18next.t("NEW")))))));
};
export default withStore(observer(SingleTemplate));
