import React, { Component, memo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { isButtonsMessage, isContactsMessage, isListMessage, isLocationMessage, isMediaMessage, isPostbackMessage, isRegularMessage, isTextMessage, MessagingChannelFeature, CHAT_STATUS, } from '@whatsper/texterchat-common';
import { isArray, isEqual } from 'lodash';
import { MEDIA_TYPE, MESSAGE_DIRECTION, MESSAGE_STATUS, TIME_FORMAT } from '../../../../constants';
import doubleCheckWhite from '../../../../assets/messageStatuses/doubleCheckWhite.svg';
import doubleCheckGrey from '../../../../assets/messageStatuses/doubleCheckGrey.svg';
import singleCheck from '../../../../assets/messageStatuses/SingleWhiteCheck.svg';
import clock from '../../../../assets/messageStatuses/ClockWhiteStatus.svg';
import crossRed from '../../../../assets/messageStatuses/Cross red.png';
import cross from '../../../../assets/messageStatuses/Cross.png';
import DocumentMessage from '../DocumentMessage';
import ImageMessage from '../ImageMessage';
import TextMessage from '../TextMessage';
import DeletedMessage from '../DeletedMessage';
import AudioMessage from '../AudioMessage';
import RichMessage from '../RichMessage';
import VideoMessage from '../VideoMessage';
import LocationMessage from '../LocationMessage';
import SystemMessage from '../SystemMessage';
import MessagePostback from '../MessagePostback';
import MessageContextMenu from './MessageContextMenu';
import MessageErrorIndicator from './MessageErrorIndicator';
import ContactMessage from '../ContactMessage';
import StickerMessage from '../StickerMessage';
import ButtonsMessage from '../ButtonsMessage';
import ListMessage from '../ListMessage';
import AdMessage from '../AdMessage';
import SpecialMessage from '../SpecialMessage';
import UserStatusMessage, { isUserStatusMessage } from '../channelSpecific/website_chat/UserStatusMessage';
import MixedMediaMessage from '../MixedMediaMessage';
import { withStore } from '../../../../store/rootStore';
import { isAdMessage, isSystemMessage } from '../../../../helpers/functions';
import ReactionMessage from '../ReactionMessage';
import SendMessageReaction from './SendMessageReaction';
import { BsChatRightTextFill } from 'react-icons/bs';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import styles from './Message.module.scss';
class Message extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "afterFooterRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                hovered: false,
            }
        });
        Object.defineProperty(this, "getMessageStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (status) => {
                switch (status) {
                    case MESSAGE_STATUS.CHANNEL_FAILED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: crossRed });
                    case MESSAGE_STATUS.MESSAGE_ACCEPTED:
                        return React.createElement("img", { className: classnames(styles.messageStatusIcon, styles.singleTick), src: singleCheck });
                    case MESSAGE_STATUS.MESSAGE_DELETED:
                        return null;
                    case MESSAGE_STATUS.MESSAGE_DELIEVERED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: doubleCheckGrey });
                    case MESSAGE_STATUS.MESSAGE_FAILED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: cross });
                    case MESSAGE_STATUS.MESSAGE_SEEN:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: doubleCheckWhite });
                    case MESSAGE_STATUS.MESSAGE_SENT:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: clock });
                    default:
                        return null;
                }
            }
        });
        /**
         *
         * @param unix_timestamp Timestamp in **Milliseconds**
         */
        Object.defineProperty(this, "getTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (unix_timestamp) => {
                const timeFormat = this.props.store.timeFormat;
                let date = new Date(unix_timestamp);
                let hours = date.getHours();
                let minutes = '0' + date.getMinutes();
                let formattedTime = hours + ':' + minutes.substr(-2);
                if (timeFormat === TIME_FORMAT.TWELVE_HOURS) {
                    if (hours > 12) {
                        let leadingZero = '0';
                        if (hours - 12 >= 10) {
                            leadingZero = '';
                        }
                        formattedTime = leadingZero + (hours - 12) + ':' + minutes.substr(-2) + ' PM';
                    }
                    else if (hours === 12 && parseInt(minutes) > 0) {
                        formattedTime += ' PM';
                    }
                    else if (hours < 12) {
                        let leadingZero = '0';
                        if (hours >= 10) {
                            leadingZero = '';
                        }
                        formattedTime = leadingZero + hours + ':' + minutes.substr(-2) + ' AM';
                    }
                    else
                        formattedTime += ' AM';
                }
                return formattedTime;
            }
        });
        Object.defineProperty(this, "getChatBubbleTitleColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { theme } = this.props.store.activeChatStore;
                if ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.colorDarker) {
                    return (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.colorDarker;
                }
                if (isArray(theme.color)) {
                    return theme.color[1];
                }
                return theme.color;
            }
        });
        Object.defineProperty(this, "allowReactionInteraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { activeChatStore, loginStore, channels } = this.props.store;
                const { sessionIsOver, chat } = activeChatStore;
                const { userDetails } = loginStore;
                const { hasFeatures } = channels;
                const showSendReaction = hasFeatures([MessagingChannelFeature.messageReactionSend]) &&
                    !sessionIsOver &&
                    chat &&
                    !((_a = chat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) &&
                    chat.status === CHAT_STATUS.ASSIGNED &&
                    chat.agent &&
                    (userDetails === null || userDetails === void 0 ? void 0 : userDetails._id) === chat.agent.uid;
                return showSendReaction;
            }
        });
    }
    render() {
        var _a, _b, _c;
        const { message, name, previousMessage, scrollToMessage, handleForwardMessage, isHighlighted, isReactionLoading, store, onSendMessageReaction, onRemoveMessageReaction, renderMessageAsHTML, } = this.props;
        const { theme, indexedImageMessages } = store.activeChatStore;
        return (React.createElement("div", { className: styles.chatMessageWrapper },
            isRegularMessage(message) && (React.createElement("div", { className: classnames(styles.messageContainer, {
                    [styles.alignEnd]: message.direction === MESSAGE_DIRECTION.OUT,
                    [styles.highlighted]: isHighlighted,
                }) },
                React.createElement("div", { className: classnames(styles.messageBox, {
                        [styles.backgroundLight]: message.direction === MESSAGE_DIRECTION.IN,
                        [styles.error]: !!message.errorReason,
                    }), onMouseEnter: () => this.setState({ hovered: true }), onMouseLeave: () => this.setState({ hovered: false }) },
                    React.createElement(SpecialMessage, { type: message.type, special: message.special, direction: message.direction, metadata: message.metadata, text: message.text, channel: message.chatChannelInfo.name, afterFooterRef: this.afterFooterRef },
                        ((_b = (_a = message.metadata) === null || _a === void 0 ? void 0 : _a.templateMessageData) === null || _b === void 0 ? void 0 : _b.id) && (React.createElement(RichMessage, { templateId: message.metadata.templateMessageData.id, parameters: message.metadata.templateParameters, templateMessageData: message.metadata.templateMessageData, hasError: !!message.errorReason, afterFooterRef: this.afterFooterRef })),
                        isAdMessage(message) && (React.createElement("div", { className: styles.adMessageInfoBox },
                            React.createElement(BsChatRightTextFill, null),
                            React.createElement(Trans, { i18nKey: "messageSentViaAd" }))),
                        isButtonsMessage(message) && (React.createElement(ButtonsMessage, { text: message.text, buttons: message.buttons, afterFooterRef: this.afterFooterRef })),
                        (message.direction === MESSAGE_DIRECTION.IN &&
                            (previousMessage === null || previousMessage === void 0 ? void 0 : previousMessage.direction) !== MESSAGE_DIRECTION.IN) ||
                            (!previousMessage && (React.createElement("p", { className: styles.nameText, style: { color: this.getChatBubbleTitleColor() } }, name))),
                        !!message.context && (React.createElement(MessagePostback, { onClick: () => { var _a; return ((_a = message.context) === null || _a === void 0 ? void 0 : _a._id) && scrollToMessage(message.context._id); }, incoming: message.direction === MESSAGE_DIRECTION.IN, context: message.context, name: name })),
                        isMediaMessage(message) ? (message.media.length > 1 ? (React.createElement(MixedMediaMessage, { message: message, shareFile: store.files.shareFile, color: theme.color })) : (message.media
                            .slice()
                            .sort((firstMedia, secondMedia) => {
                            if (!firstMedia.caption && secondMedia.caption) {
                                return -1;
                            }
                            else if (firstMedia.caption && !secondMedia.caption) {
                                return 1;
                            }
                            return 0;
                        })
                            .map((media, i) => {
                            if ([MEDIA_TYPE.AUDIO].includes(media.mediaType)) {
                                return React.createElement(AudioMessage, { media: media, key: media.fileId || i });
                            }
                            if ([MEDIA_TYPE.DOC].includes(media.mediaType)) {
                                return (React.createElement(DocumentMessage, { message: message, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, color: theme.color, key: media.fileId, renderMessageAsHTML: renderMessageAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.IMAGE) {
                                return (React.createElement(ImageMessage, { messageId: message._id, direction: message.direction, media: media, imageMessages: indexedImageMessages, showCaption: message.media.length - 1 === i, key: `${i}-${media.fileId}`, renderMessageAsHTML: renderMessageAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.VIDEO) {
                                return (React.createElement(VideoMessage, { media: media, direction: message.direction, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, key: media.fileId || i, renderMessageAsHTML: renderMessageAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.STICKER) {
                                return React.createElement(StickerMessage, { media: media, key: media.fileId || i });
                            }
                        }))) : null,
                        isListMessage(message) && (React.createElement(ListMessage, { list: message.list, text: message.text, status: message.status })),
                        isAdMessage(message) && (React.createElement(AdMessage, { referral: ((_c = message.special) === null || _c === void 0 ? void 0 : _c.facebook).referral, shareFile: store.files.shareFile })),
                        isTextMessage(message) && (React.createElement(TextMessage, { text: message.text, deleted: message.status === MESSAGE_STATUS.MESSAGE_DELETED, outgoing: message.direction === MESSAGE_DIRECTION.OUT, renderMessageAsHTML: renderMessageAsHTML })),
                        isPostbackMessage(message) && (React.createElement(TextMessage, { text: message.postback.title || message.postback.payload, deleted: message.status === MESSAGE_STATUS.MESSAGE_DELETED, outgoing: message.direction === MESSAGE_DIRECTION.OUT, renderMessageAsHTML: renderMessageAsHTML })),
                        isLocationMessage(message) && message.location ? (React.createElement(LocationMessage, { longitude: message.location.longitude, latitude: message.location.latitude })) : null,
                        isContactsMessage(message) && message.contacts ? (React.createElement(ContactMessage, { contacts: message.contacts, incoming: message.direction === MESSAGE_DIRECTION.IN })) : null,
                        isUserStatusMessage(message) ? (React.createElement(UserStatusMessage, { message: message, color: this.getChatBubbleTitleColor() })) : null,
                        message.status === MESSAGE_STATUS.MESSAGE_DELETED && React.createElement(DeletedMessage, null)),
                    React.createElement(MessageContextMenu, { hovered: this.state.hovered, incoming: message.direction === MESSAGE_DIRECTION.IN, messageId: message._id, message: message, handleForwardMessage: handleForwardMessage }),
                    this.allowReactionInteraction() && (React.createElement(SendMessageReaction, { onSendMessageReaction: onSendMessageReaction, incoming: message.direction === MESSAGE_DIRECTION.IN })),
                    React.createElement("div", { className: styles.receiver },
                        React.createElement("p", { className: classNames(styles.timestamp, {
                                [styles.timestampUser]: message.direction === MESSAGE_DIRECTION.OUT,
                            }) }, this.getTime(message.timestamp || message.received)),
                        message.direction === MESSAGE_DIRECTION.OUT && this.getMessageStatus(message.status))),
                React.createElement("div", { ref: this.afterFooterRef, className: styles.afterFooterContainer }),
                message.reactions && (React.createElement(ReactionMessage, { reactions: message.reactions, history: message.reactionsHistory, direction: message.direction, loading: isReactionLoading, onRemoveMessageReaction: this.allowReactionInteraction() ? onRemoveMessageReaction : undefined })),
                message.errorReason && React.createElement(MessageErrorIndicator, { message: message.errorReason }))),
            isSystemMessage(message) && (React.createElement(SystemMessage, { messageId: message._id, newChatStatus: message.newChatStatus, agent: message.agent, assignedToAgent: message.assignedToAgent, assignedToDepartment: message.assignedToDepartment, time: this.getTime(message.timestamp || message.received || 0) }))));
    }
}
export default memo(withStore(observer(Message)), (prevProps, nextProps) => {
    const propsToTrack = [
        'message',
        'previousMessage',
        'name',
        'activeChat',
        'isHighlighted',
        'isReactionLoading',
        'renderMessageAsHTML',
    ];
    const prevPropsToTrack = propsToTrack.map((prop) => prevProps[prop]);
    const nextPropsToTrack = propsToTrack.map((prop) => nextProps[prop]);
    if (!isEqual(prevPropsToTrack, nextPropsToTrack))
        return false;
    return true;
});
