import React from 'react';
import { Trans } from 'react-i18next';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { MyForm, MyInput } from '../../../../Base/Form';
import LoginMethodWithTitle from '../../LoginMethodWithTitle';
import s from '../../../Login.module.scss';
const PasswordLogin = ({ onSubmitHandler, formData, setFormData, loading, disabled }) => {
    return (React.createElement(LoginMethodWithTitle, { title: React.createElement(Trans, { i18nKey: "passwordSignInTitle" }) },
        React.createElement(MyForm, { onSubmit: onSubmitHandler, loading: loading, disabled: disabled },
            React.createElement(MyInput, { key: "email", id: "email", name: "email", type: "text", placeholder: "email", labelKey: "email", value: formData.email, onChangeHandler: (value) => setFormData(Object.assign(Object.assign({}, formData), { email: value })), className: s.input, warnIfContainsInvisibleCharacters: true }),
            React.createElement(MyInput.Password, { key: "password", id: "password", name: "password", type: "password", labelKey: "password", placeholder: "- - - -", value: formData.password, onChangeHandler: (value) => setFormData(Object.assign(Object.assign({}, formData), { password: value })), className: s.input, iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)), warnIfContainsInvisibleCharacters: true }))));
};
export default PasswordLogin;
